import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { DonateSubscriptionPaypalButton } from '../../../components/Channel/Donate/Subscription/PaypalButton';
import { FiatCurrency } from '../../../lib/currency';
import { useProfile } from '../../../components/Profile/ProfileContext';
import BuyPointsSuccess from '../../BuyPoints/BuyPointsSuccess';
import SubscriptionCompleted from '../../SubscriptionCompleted/SubscriptionCompleted';
import { OrderBundleType, PaypalOrderButton } from './Order/Button';
import { PaypalSubscriptionButton } from './Subscription/Button';
import { GRAPH_GLOBALS_QUERY_KEYS } from '../../../api-hooks/graph/globals';

export const PaypalCheckout = ({
	plan,
	amount,
	orderBundleType,
	channelId,
	includeFee,
	selectedResource,
	resourcePurchaseSuccess,
	goalId,
	isDonationSubscription,
}) => {
	const [pointsCheckoutComplete, setPointsCheckoutComplete] = useState(false);
	const [subscriptionCheckoutComplete, setSubscriptionCheckoutComplete] = useState(false);

	const { fetchProfile, profile } = useProfile();
	const queryClient = useQueryClient();

	const onSubscriptionComplete = useCallback(() => {
		fetchProfile();
		setSubscriptionCheckoutComplete(true);
	}, [fetchProfile]);

	const handleCheckoutSuccess = useCallback(() => {
		setPointsCheckoutComplete(true);
		queryClient.invalidateQueries(GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(profile?._id));
		resourcePurchaseSuccess();
	}, [queryClient, profile, resourcePurchaseSuccess]);

	if (subscriptionCheckoutComplete) {
		return <SubscriptionCompleted />;
	}

	if (pointsCheckoutComplete) {
		return (
			<BuyPointsSuccess
				amount={amount}
				orderBundleType={orderBundleType}
			/>
		);
	}

	if (orderBundleType === OrderBundleType.DONATION && isDonationSubscription) {
		return (
			<DonateSubscriptionPaypalButton
				amount={amount}
				channelId={channelId}
				includeFee={includeFee}
				goalId={goalId}
				currency={FiatCurrency.USD}
				onDonationSuccess={() => setPointsCheckoutComplete(true)}
			/>
		);
	}

	return plan ? (
		<PaypalSubscriptionButton
			plan={plan}
			onSubscriptionComplete={onSubscriptionComplete}
		/>
	) : (
		<PaypalOrderButton
			amount={amount}
			onOrderPaymentComplete={handleCheckoutSuccess}
			orderBundleType={orderBundleType}
			channelId={channelId}
			includeFee={includeFee}
			selectedResource={selectedResource}
			goalId={goalId}
		/>
	);
};

PaypalCheckout.propTypes = {
	plan: PropTypes.string,
	amount: PropTypes.number,
	orderBundleType: PropTypes.string,
	channelId: PropTypes.string,
	includeFee: PropTypes.bool,
	selectedResource: PropTypes.shape({
		id: PropTypes.string,
		type: PropTypes.string,
	}),
	resourcePurchaseSuccess: PropTypes.func,
	goalId: PropTypes.string,
	isDonationSubscription: PropTypes.bool,
};

PaypalCheckout.defaultProps = {
	plan: '',
	amount: 0,
	orderBundleType: OrderBundleType.PI_POINTS,
	channelId: undefined,
	includeFee: false,
	selectedResource: undefined,
	resourcePurchaseSuccess: undefined,
	goalId: undefined,
	isDonationSubscription: undefined,
};
