/* eslint-disable react/prop-types */
// @ts-check

import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { PublicPathPrefix } from '../../RoutePath';

/**
 * @typedef {{
 * 	container?: string | HTMLElement | React.RefObject<HTMLElement>,
 * 	errorName?: string,
* 	message?: string,
 * 	target: string | HTMLElement | React.RefObject<HTMLElement>,
 *  showHelpLink?: boolean,
 * }} ErrorPopoverProps
 */

/** @type {React.FC<ErrorPopoverProps>} */
export const ErrorPopover = ({ container, errorName, message, showHelpLink = true, target }) => {
	const { t } = useTranslation();

	if (!message) return null;

	return (
		<UncontrolledPopover
			container={container}
			placement="bottom"
			target={target}
			trigger="hover"
		>
			<PopoverBody className="text-black font-size-sm">
				<b className="text-danger">{message}</b>
				{errorName && <span> {errorName}</span>}.<br />
				{showHelpLink && (
					<span>{t('LocalUserMedia.ErrorPopover.seeOur')}
						<a href={`${PublicPathPrefix}/help/troubleshooting`} target="_blank" rel="noopener noreferrer">{t('LocalUserMedia.ErrorPopover.troubleshootingGuide')}
							<FaExternalLinkAlt />
						</a>.
					</span>
				)}
			</PopoverBody>
		</UncontrolledPopover>
	);
};
