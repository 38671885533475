/* eslint-disable react/prop-types */
// @ts-check

import { useTranslation } from 'react-i18next';

import { ErrorPopover } from '../../../ErrorPopover/ErrorPopover';

/**
 * @param {Error} error
 * @param {string} type
 * @param {import('i18next').TFunction} t
 * @returns {string}
 */
const getErrorMessage = (error, type, t) => {
	const isDeniedError = error.name === 'NotAllowedError';

	if (isDeniedError) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouNotPermitted')} ${type}`;
	}

	if (['video'].includes(type)) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouCannotRead')} ${type}`;
	}

	if (['screen'].includes(type)) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouCannotShare')} ${type}`;
	}

	if (['camera', 'microphone'].includes(type)) {
		return `${t('LocalUserMedia.MediaErrorPopover.beeyouCannotAccess')} ${type}`;
	}

	return t('Global.error');
};

/**
 * @typedef {{
 * 	error?: Error,
 * 	target: string,
 * 	type: string,
 * }} MediaErrorPopoverProps
 */

/** @type {React.FC<MediaErrorPopoverProps>} */
export const MediaErrorPopover = ({ error, target, type }) => {
	const { t } = useTranslation();

	if (!error) return null;

	return (
		<ErrorPopover
			errorName={error.name}
			message={getErrorMessage(error, type, t)}
			target={target}
		/>
	);
};
