/* eslint-disable react/prop-types */
// @ts-check

import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import Select, { components } from 'react-select';
import { FaSearch, FaUserFriends } from 'react-icons/fa';

import Hexagon from '../Hexagon/Hexagon';
import { getAvatar } from '../StudioSchedule/Participants/Participants.helper';
import { useFetchUserSuggestions } from '../../api-hooks/search';

/**
 * @import { IUserSuggestionDto } from '../../api/search.dto';
 * @import {
 * 	ValueContainerProps as RSValueContainerProps,
 *  OptionProps,
 * 	InputActionMeta,
 *  StylesConfig,
 *  Props as RSProps,
 * 	MultiValue,
 * 	SingleValue,
 * } from 'react-select';
 */

/**
 * @typedef {{
* 	label: IUserSuggestionDto['label'],
* 	user: IUserSuggestionDto['user'] & {
* 		isFriend: IUserSuggestionDto['isFriend'],
* 		isOperator: IUserSuggestionDto['isOperator'],
* 	},
* value: IUserSuggestionDto['id'],
* }} SelectUsersMultiSuggestion
*/

const projectName = import.meta.env.VITE_PROJECT;

/**
 * @param {boolean} showSearchIcon
 * @returns {StylesConfig<SelectUsersMultiSuggestion>}
 */
const getCustomStyles = (showSearchIcon) => ({
	dropdownIndicator: (baseStyles) => ({
		...baseStyles,
		display: 'none',
	}),
	indicatorSeparator: (baseStyles) => ({
		...baseStyles,
		display: 'none',
	}),
	...(showSearchIcon
		? { valueContainer: (baseStyles) => ({
			...baseStyles,
			paddingLeft: 24,
		}) }
		: {}
	),
});

/**
 * @typedef {Omit<OptionProps, 'data'> & {
 * 	data: SelectUsersMultiSuggestion,
 * }} CustomOptionProps
 */

/** @type {React.FC<CustomOptionProps>} */
const CustomOption = ({ data, innerProps, innerRef, isFocused }) => {
	const { t } = useTranslation();
	return (
		<div ref={innerRef} {...innerProps} className={`d-flex py-1 ${isFocused ? 'selected' : ''}`}>
			<Hexagon className="d-block d-40 flex-shrink-0 ms-3">
				<img src={getAvatar(data.user.avatar)} className="img-fluid" alt="Avatar" />
			</Hexagon>
			<div className="d-flex justify-content-between w-100 align-items-center mx-3 overflow-hidden">
				<strong className="font-size-sm text-truncate text-secondary">{(data.user.isFriend || data.user.isOperator) && (<FaUserFriends className="mr-2" />)} {data.label}</strong>
				<div className="d-flex align-items-center ms-3">
					<Badge color={data.user.isConnected ? 'success' : 'second'} className="position-relative badge-circle ml-1" style={{ lineHeight: '15px' }}>
						{t('Share.ItemFriend.online')}
					</Badge>
					<span className="ml-2 content-light-50 font-size-sm text-nowrap">{data.user.isConnected ? t('Share.ItemFriend.online') : t('Share.ItemFriend.offline')}</span>
				</div>
			</div>
		</div>
	);
};

/**
 * @typedef {Omit<RSValueContainerProps, 'children'> & {
 * 	children?: React.ReactNode,
 * }} ValueContainerProps
 */

/** @type {React.FC<ValueContainerProps>} */
const ValueContainer = (
	{ children, ...props },
) => (
	components.ValueContainer && (
		<components.ValueContainer {...props}>
			{!!children && (
				<FaSearch
					className="text-secondary"
					style={{ position: 'absolute', left: 6 }}
				/>
			)}
			{children}
		</components.ValueContainer>
	)
);

/**
 * @typedef {Omit<RSProps<SelectUsersMultiSuggestion>, 'styles'> & {
 * alreadySelectedUsersIds?: string[],
 * color?: 'dark' | 'dark-border' | 'light' | 'light-border',
 * defaultInputValue?: string,
 * excludeMe?: boolean,
 * isClearable?: boolean,
 * isLoading?: boolean,
 * isMulti?: boolean,
 * operatorsOnly?: boolean,
 * placeholder?: string,
 * propSuggestions?: IUserSuggestionDto[],
 * setPropSuggestionsSearchValue?: (newValue: string, actionMeta?: InputActionMeta) => void,
 * setUsers: (users: SingleValue<SelectUsersMultiSuggestion>
 * 	| MultiValue<SelectUsersMultiSuggestion>) => void,
 * showSearchIcon?: boolean,
 * styles?: StylesConfig<SelectUsersMultiSuggestion>,
 * suggestionsFilter?: (suggestion: IUserSuggestionDto) => boolean,
 * users: SelectUsersMultiSuggestion[],
 * }} SelectUsersMultiProps
 */

/**
 * @type {React.FC<SelectUsersMultiProps>}
 *
 * In this component, the suggestions either come from the props or are fetched from the API.
 */
export const SelectUsersMulti = ({
	alreadySelectedUsersIds = [],
	color = 'dark',
	defaultInputValue = '',
	excludeMe = true,
	isClearable = false,
	isLoading = false,
	isMulti = true,
	operatorsOnly = false,
	placeholder,
	propSuggestions,
	setPropSuggestionsSearchValue,
	setUsers,
	showSearchIcon = true,
	styles = {},
	suggestionsFilter = () => true,
	users,
	...props
}) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');

	const { data: fetchedSuggestions, isLoading: isSuggesting } = useFetchUserSuggestions(
		searchValue, { operatorsOnly, excludeMe },
	);

	const suggestions = propSuggestions ?? fetchedSuggestions;

	// Filter suggestions:
	// - Remove already selected users
	// - Remove users that don't match the prop filter
	/** @type {SelectUsersMultiSuggestion[]} */
	const filteredSuggestions = useMemo(() => (suggestions || [])
		.filter((user) => !alreadySelectedUsersIds.includes(user.id))
		.filter((suggestion) => suggestionsFilter(suggestion))
		.sort((a, b) => {
			// eslint-disable-next-line no-nested-ternary
			const aScore = a.isOperator ? 2 : a.isFriend ? 1 : 0;
			// eslint-disable-next-line no-nested-ternary
			const bScore = b.isOperator ? 2 : b.isFriend ? 1 : 0;
			return bScore - aScore;
		})
		.map((suggestion) => ({
			label: suggestion.label,
			user: {
				...suggestion.user,
				isFriend: suggestion.isFriend,
				isOperator: suggestion.isOperator,
			},
			value: suggestion.id,
		})), [suggestions, alreadySelectedUsersIds, suggestionsFilter]);

	const customStyles = useMemo(() => getCustomStyles(showSearchIcon), [showSearchIcon]);

	return 	(
		<Select
			captureMenuScroll={false}
			defaultInputValue={defaultInputValue}
			classNamePrefix={`react-select-${color}`}
			closeMenuOnSelect={!propSuggestions}
			components={{
				Option: CustomOption,
				...(showSearchIcon ? { ValueContainer } : {}),
			}}
			hideSelectedOptions
			isLoading={isSuggesting || isLoading}
			isSearchable
			isMulti={isMulti}
			isClearable={isClearable}
			noOptionsMessage={() => null}
			onChange={setUsers}
			onInputChange={(value) => (setPropSuggestionsSearchValue
				? setPropSuggestionsSearchValue(value) : setSearchValue(value))}
			options={filteredSuggestions}
			placeholder={placeholder || t('SelectUsersMulti.SelectUsersMulti.searchForUser', { projectName })}
			styles={{ ...customStyles, ...styles }}
			loadingMessage={() => t('SelectUsersMulti.SelectUsersMulti.loading')}
			value={users}
			{...props}
		/>
	);
};
