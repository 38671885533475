// @ts-check

/**
 * @typedef {{
 *  peerExceptions?: string[],
 *  peerReceiver?: string,
 *  streamType: string,
 * }} TrackAppData
 */

/**
 * Store subscribed MediaStreamTrack, available for consumption.
 */
export class Track {
	/**
	 * @param {MediaStreamTrack | { id: string }} mediaStreamTrack
	 * @param {TrackAppData} [appData]
	 */
	constructor(mediaStreamTrack, appData) {
		this.mediaStreamTrack = mediaStreamTrack;
		this.appData = appData;
	}

	get id() {
		return this.mediaStreamTrack.id;
	}
}
