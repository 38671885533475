import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAsyncCallback } from 'react-async-hook';
import {
	Button,
	Input,
	FormGroup,
	Label,
	Alert,
	Row,
	Col,
	Popover,
	PopoverBody,
} from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';

import { FaInfoCircle } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { useCreateDonationSubscription, useFetchDonationSubscription } from '../../../api-hooks/channel/donation/subscription';
import { createDonation } from '../../../api/channel/donation/donation';
import { FormModal } from '../../Form/Modal';
import FormSubmit from '../../Form/Submit';
import { ButtonPill } from '../../Button';
import { Currency, currencyLabel, FiatCurrency, FiatCurrencyLabel, FiatCurrencyWithPiPoints } from '../../../lib/currency';
import { OrderBundleType } from '../../../views/Checkout/Paypal/Order/Button';
import { useCalculatePoints } from '../../../lib/useCalculatePoints';
import { useProfile } from '../../Profile/ProfileContext';
import { usePoints } from '../../Membership/Points';
import { useCalculatePrice } from '../../../lib/useCalculatePrice';
import { PaypalCheckout } from '../../../views/Checkout/Paypal/Checkout';
import { FormLabel } from '../../Form/Label';
import { GOALS_QUERY_KEYS } from '../../../api-hooks/channel/goals';
import { DonateSubscription } from './Subscription/Subscription';
import { useAsyncErrorLog } from '../../../lib/hooks';

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		amount: {
			type: 'number',
			minimum: 0,
		},
		currency: {
			type: 'string',
			enum: [...Object.values(FiatCurrency), Currency.PI_POINTS],
		},
		includeFee: {
			type: 'boolean',
		},
		subscribe: {
			type: 'boolean',
		},
	},
	required: ['amount', 'currency'],
};

const DONATION_FEE = 0.03;

const DonateModal = ({
	isOpen,
	channel,
	toggle,
	goalId,
}) => {
	const { profile } = useProfile();

	const {
		mutateAsync: createDonationSubscriptionAsync,
		isLoading: isCreateDonationSubscriptionLoading,
	} = useCreateDonationSubscription();

	const { t } = useTranslation();
	const [formData, setFormData] = useState({
		amount: 0,
		currency: profile?.preferences?.currency ?? FiatCurrency.USD,
		includeFee: false,
		subscribe: false,
	});
	const [showPopover, setShowPopover] = useState(false);
	const { balance } = usePoints();

	const { data: donationSubscription } = useFetchDonationSubscription(channel?._id);

	const queryClient = useQueryClient();

	const isPointsDonation = useMemo(() => (
		formData.currency === Currency.PI_POINTS
	), [formData]);

	const calculatePoints = useCalculatePoints(
		OrderBundleType.PI_POINTS,
		!formData.currency !== FiatCurrencyWithPiPoints.PI_POINTS
			? formData.currency
			: FiatCurrency.USD,
	);
	const calculatePrice = useCalculatePrice(
		OrderBundleType.PI_POINTS,
		!formData.currency !== FiatCurrencyWithPiPoints.PI_POINTS
			? formData.currency
			: FiatCurrency.USD,
	);

	const handleChange = useCallback((data) => {
		setFormData(data);
	}, []);

	const onSubmit = async (amount, includeFee, isSubscription) => {
		if (isSubscription) {
			await createDonationSubscriptionAsync({
				amount,
				channelId: channel._id,
				includeFee,
				goalId,
			});
		} else {
			await createDonation(
				channel._id,
				{ amount, includeFee, goalId },
			);
		}

		if (goalId) {
			queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchOneGoalById(goalId));
			queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchGoalsByChannelId(channel._id));
		}
	};

	const handleSubmitAsync = useAsyncCallback(async () => (
		onSubmit(formData.amount, formData.includeFee, formData.subscribe)
	));
	const { error } = handleSubmitAsync;
	useAsyncErrorLog({ error });

	const isSuccess = handleSubmitAsync.status === 'success';
	const unexpectedErrorMsg = t('Donate.Modal.error');
	const errorMessage = error && (error.response?.data?.message || unexpectedErrorMsg);
	const errorReason = error?.response?.data?.reason;
	const isInsufficientFoundsError = errorReason === 'INSUFFICIENT_FUNDS';
	const isGeneralError = error && !isInsufficientFoundsError;

	const remainingPiPoints = useMemo(() => (
		balance - formData.amount
	), [balance, formData]);

	const requiredPiPoints = useMemo(() => (
		formData.amount - balance
	), [balance, formData]);

	const insufficientFunds = useMemo(() => remainingPiPoints < 0, [remainingPiPoints]);

	const showInsufficientFunds = useMemo(() => (
		formData.subscribe && !!donationSubscription
			? false
			: (isInsufficientFoundsError || (insufficientFunds && !isSuccess))
			&& formData.currency === FiatCurrencyWithPiPoints.PI_POINTS
	), [donationSubscription, formData, insufficientFunds, isInsufficientFoundsError, isSuccess]);

	const handleToggleMonthlySubscription = useCallback((isSubscription) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			subscribe: isSubscription,
		}));
	}, []);

	return (
		<FormModal
			contentClassName="form-dark"
			isOpen={isOpen}
			showCloseButton={!handleSubmitAsync.loading}
			toggle={toggle}
		>
			<h1 className="display-4 mb-5 font-weight-bold">{t('Donate.Modal.donateTo')} {channel.nickname}</h1>
			<Form
				data={formData}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={schema}
			>
				<Row className="justify-content-center d-flex w-100">
					<FormGroup className="d-flex justify-content-center w-100" tag="fieldset">
						<FormGroup className="mr-4" check>
							<Field
								component={Input}
								type="radio"
								id="once"
								onChange={() => handleToggleMonthlySubscription(false)}
								checked={!formData.subscribe}
							/>
							<Label check for="once" className="blue content-dark">
								Once
							</Label>
						</FormGroup>
						<FormGroup check>
							<Field
								component={Input}
								type="radio"
								name="subscribe"
								id="subscribe"
								onChange={() => handleToggleMonthlySubscription(true)}
								checked={formData.subscribe}
							/>
							<Label check for="subscribe" className="blue content-dark">
								Monthly
							</Label>
						</FormGroup>
					</FormGroup>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label for="amount">
								{t('Donate.Modal.amount')}
							</Label>
							<Field
								component={Input}
								type="number"
								name="amount"
								id="amount"
								placeholder="20"
								min={1}
								value={formData.amount}
							/>
							<FieldError name="amount" />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="currency">{t('Donate.Modal.currency')}</Label>
							<Field
								component={Input}
								type="select"
								name="currency"
								className=""
								value={formData.currency}
							>
								{Object.keys(FiatCurrency).map((currencyKey) => (
									<option key={`option-${currencyKey}`} value={FiatCurrency[currencyKey]}>
										{FiatCurrencyLabel[currencyKey]}
									</option>
								))}
								<option value={Currency.PI_POINTS}>
									{t('Donate.Modal.existingCurrency')}
								</option>
							</Field>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup id="IncludeFeeContainer">
							<FormLabel>
								<Field
									checked={formData.includeFee}
									component={Input}
									name="includeFee"
									type="checkbox"
								/>
								<span className="ml-2">{t('Donate.Modal.includeFee')}</span>
							</FormLabel>
							<span
								onMouseEnter={() => setShowPopover(true)}
								onMouseLeave={() => setShowPopover(false)}
								id="IncludeFeeIcon"
							>
								<FaInfoCircle
									size={12}
									className="ml-1"
								/>
							</span>
							<Popover
								placement="bottom"
								isOpen={showPopover}
								target="IncludeFeeIcon"
								container="IncludeFeeContainer"
							>
								<PopoverBody className="text-black">
									{t('Donate.Modal.fee')}
								</PopoverBody>
							</Popover>
							<FieldError name="includeFee" />
						</FormGroup>
					</Col>
					{formData.currency === FiatCurrencyWithPiPoints.USD && (
						<Col>
							Total: ${formData.amount + (formData.includeFee
								? Math.floor(formData.amount * DONATION_FEE)
								: 0)}
						</Col>
					)}
				</Row>
				<footer className="mt-2">
					{!isSuccess && isPointsDonation && (
						<div>
							{!insufficientFunds ? (
								<>
									<p className="mb-0">{t('Donate.Modal.available')}: <span>{balance} {currencyLabel}</span></p>
									<p className="mb-0">{t('Donate.Modal.donation')}: <span>{formData.amount} {currencyLabel}</span></p>
									<p className="mb-0">{t('Donate.Modal.remaining')}: <span className="text-success">{remainingPiPoints} {currencyLabel}</span></p>
								</>
							) : null}
						</div>
					)}
					{isGeneralError && <Alert color="danger">{errorMessage}</Alert>}
					{formData.subscribe && (
						<DonateSubscription
							amount={formData.amount}
							channelId={channel._id}
							includeFee={formData.includeFee}
							goalId={goalId}
							currency={formData.currency}
							showInsufficientFunds={showInsufficientFunds}
						/>
					)}
					{!formData.subscribe && !isPointsDonation && (
						<PaypalCheckout
							amount={calculatePoints(formData.amount)}
							orderBundleType={OrderBundleType.DONATION}
							channelId={channel._id}
							includeDonationFee={formData.includeFee}
							goalId={goalId}
						/>
					)}
					{isSuccess && (
						<Alert color="success">
							{t('Donate.Modal.donationSuccessfull')} {channel.nickname}
						</Alert>
					)}
					<div className="d-flex">
						<Button
							color="link"
							disabled={handleSubmitAsync.loading}
							onClick={toggle}
						>
							{t('Donate.Modal.cancel')}
						</Button>
						{!isSuccess && isPointsDonation && !insufficientFunds && (
							<FormSubmit className="ml-auto" loading={handleSubmitAsync.loading || isCreateDonationSubscriptionLoading}>
								{t(formData.subscribe ? 'Donation.Subscriptions.subscribe' : 'Donate.Modal.donate')}
							</FormSubmit>
						)}
						{isSuccess && (
							<ButtonPill
								className="ml-auto"
								color="info"
								onClick={toggle}
							>
								{t('Donate.Modal.close')}
							</ButtonPill>
						)}
					</div>
				</footer>
			</Form>
		</FormModal>
	);
};

DonateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		nickname: PropTypes.string.isRequired,
	}).isRequired,
	toggle: PropTypes.func.isRequired,
	goalId: PropTypes.string,
};

DonateModal.defaultProps = {
	goalId: undefined,
};

export default DonateModal;
