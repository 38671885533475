/* eslint-disable react/prop-types */
// @ts-check

import { useMatch } from 'react-router-dom';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { ModeScreenLayout, useScreenMode } from '../../components/Studio/ScreenMode/Provider';
import HeaderAnonymousBox from '../HeaderAnonymousBox';
import HeaderUserbox from '../HeaderUserbox';
import { StudioFullscreenHeaderStatusBadge } from '../../components/Studio/StudioHeaderStatusBadge';
import { StudioStatusProvider } from '../../components/Studio/Status/Provider';
import { useCurrentStudio } from '../../components/Studio/useCurrentStudio';
import { StudioButtonActive, StudioButtonLeave } from '../../components/Studio/Button';
import { getLink, Path } from '../../RoutePath';
import { PlayerControlsDisplayProvider } from '../../components/Player/ControlsDisplay/Provider';

import './StudioHeader.scss';

/**
 * @typedef {{
 *  className?: string,
 *  showDonationToStudioOwner: boolean,
 *  toggleDonateModal: () => void,
 * }} StudioFullscreenHeaderProps
 */

export const StudioFullscreenHeader = (
/** @type {StudioFullscreenHeaderProps} */
	{ showDonationToStudioOwner, toggleDonateModal },
) => {
	const { isLoggedIn } = useAuthentication();
	const { currentModeScreen } = useScreenMode();
	const { currentStudio } = useCurrentStudio();

	const isHostRoute = useMatch({
		path: getLink(
			Path.STUDIO_HOST,
			{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
		),
		end: false,
	});
	const isOperatorRoute = useMatch({ path: getLink(
		Path.STUDIO_OPERATOR,
		{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
	) });

	const isController = isHostRoute || isOperatorRoute;
	const isFullscreen = currentModeScreen === ModeScreenLayout.FULLSCREEN;

	return (
		<PlayerControlsDisplayProvider
			enabled={isFullscreen}
			target=".studio-fullscreen"
		>
			<StudioStatusProvider studio={currentStudio}>
				<div
					className="d-block position-absolute w-100 app-main layout-fullscreen"
					id="Header"
				>
					<div className="app-header StudioFullscreenHeader d-flex justify-content-between py-0 my-0" style={{ height: '55px' }}>
						{isController ? (
							<StudioButtonActive isFullscreen={isFullscreen} />
						) : (
							<StudioButtonLeave isFullscreen />
						)}
						<StudioFullscreenHeaderStatusBadge
							showDonationToStudioOwner={showDonationToStudioOwner}
							toggleDonateModal={toggleDonateModal}
							isController={!!isController}
						/>
						<div>
							{isLoggedIn ? <HeaderUserbox isFullscreen /> : <HeaderAnonymousBox />}
						</div>
					</div>
				</div>
			</StudioStatusProvider>
		</PlayerControlsDisplayProvider>
	);
};
