// @ts-check

import { useEffect, useMemo, useRef, useState } from 'react';
import { getCrossOriginFileUrl } from '../../../../file';

import { useConst } from '../../../lib/hooks';
import { USER_MAX_FPS } from '../utils';
import { KeyDetectionMode, KeyReplacementMode } from './KeyConfig';
import { createCanvasAndContext } from '../../../lib/canvas';

/** @typedef {import("./KeyConfig").KeyConfig} KeyConfig */
/** @typedef {import('@tensorflow-models/body-pix')} BodyPixModule */
/** @typedef {import('@tensorflow-models/body-pix').BodyPix} BodyPix */

/**
 * @typedef {{
 *   isReady: boolean
 * }} ReadyState
 * @typedef {HTMLImageElement & ReadyState} HTMLImageElementReady
 */

/**
 * @typedef {{
 *   isKey: boolean
 * }} KeyTrack
 * @typedef {MediaStreamTrack & KeyTrack} MediaStreamKeyTrack
 */

const importTensorFlow = async () => {
	const [bodyPix] = await Promise.all([
		import('@tensorflow-models/body-pix'),
		import('@tensorflow/tfjs-core'),
		import('@tensorflow/tfjs-converter'),
		import('@tensorflow/tfjs-backend-webgl'),
	]);
	return bodyPix;
};
/**
 *
 * @param {boolean} [offscreen]
 * @param {{}} [contextOptions]
 * @returns {ReturnType<typeof createCanvasAndContext>}
 */
const useCanvasAndContext = (offscreen, contextOptions) => useConst(() => {
	const canvasAndContext = createCanvasAndContext({
		offscreen,
		contextOptions,
	});
	return canvasAndContext;
});

const replacePixel = ({ r, g, b }, detectionConf) => {
	const { color, sensitivity } = detectionConf;
	if (!color) return true;
	return (
		(r <= color.r + sensitivity && r >= color.r - sensitivity)
		&& (g <= color.g + sensitivity && g >= color.g - sensitivity)
		&& (b <= color.b + sensitivity && b >= color.b - sensitivity)
	);
};

const updateBackgroundCanvas = (img, background) => {
	if (!img) {
		background.context.clearRect(
			0, 0, background.canvas.width, background.canvas.height,
		);
		return;
	}

	if (
		!img.isReady
		&& img.complete
	) {
		background.canvas.width = img.width;
		background.canvas.height = img.height;
		background.context.clearRect(
			0, 0, background.canvas.width, background.canvas.height,
		);
		background.context.drawImage(
			img,
			0,
			0,
			background.canvas.width,
			background.canvas.height,
		);
		img.isReady = true;
	}
};

const runPostProcessing = (
	src,
	backgroundDarkeningMask,
	replacementConfig,
	{
		background,
		img,
		output,
		person,
		segmentationMask,
	},
) => {
	// mask -> segmentation
	segmentationMask.canvas.width = backgroundDarkeningMask.width;
	segmentationMask.canvas.height = backgroundDarkeningMask.height;
	segmentationMask.context.clearRect(
		0, 0, segmentationMask.canvas.width, segmentationMask.canvas.height,
	);
	segmentationMask.context.putImageData(backgroundDarkeningMask, 0, 0);

	const {
		color: replacementColor,
		edgeBlur,
		mode: replacementMode,
		transparentColor,
	} = replacementConfig;

	const doBlurPerson = edgeBlur > 0;

	if (doBlurPerson) {
		// segmentation -> person blured
		person.canvas.width = segmentationMask.canvas.width;
		person.canvas.height = segmentationMask.canvas.height;
		person.context.clearRect(0, 0, person.canvas.width, person.canvas.height);
		person.context.globalCompositeOperation = 'source-over';
		person.context.filter = `blur(${edgeBlur}px)`;
		person.context.drawImage(segmentationMask.canvas, 0, 0);
	}

	// source -> output
	output.context.clearRect(0, 0, output.canvas.width, output.canvas.height);
	output.context.filter = 'none';
	output.context.globalCompositeOperation = 'source-over';
	output.context.drawImage(src, 0, 0, output.canvas.width, output.canvas.height);

	// person blured -> output
	output.context.filter = 'none';
	output.context.globalCompositeOperation = 'destination-in';
	output.context.drawImage(
		doBlurPerson ? person.canvas : segmentationMask.canvas,
		0,
		0,
		output.canvas.width,
		output.canvas.height,
	);

	output.context.filter = 'none';
	output.context.globalCompositeOperation = 'destination-over';

	if (replacementMode === KeyReplacementMode.TRANSPARENT) {
		output.context.rect(0, 0, output.canvas.width, output.canvas.height);
		output.context.fillStyle = `rgb(${transparentColor.r}, ${transparentColor.g}, ${transparentColor.b})`;
		output.context.fill();
		return;
	}

	if (replacementMode === KeyReplacementMode.COLOR) {
		output.context.rect(0, 0, output.canvas.width, output.canvas.height);
		output.context.fillStyle = `rgb(${replacementColor.r}, ${replacementColor.g}, ${replacementColor.b})`;
		output.context.fill();
		return;
	}

	if (replacementMode === KeyReplacementMode.IMAGE) {
		updateBackgroundCanvas(img, background);
		if (background.canvas.width > 0 && background.canvas.height > 0) {
			output.context.drawImage(
				background.canvas,
				0,
				0,
				output.canvas.width,
				output.canvas.height,
			);
		}
		return;
	}

	output.context.fillStyle = 'black';
	output.context.fillRect(0, 0, output.canvas.width, output.canvas.height);
	output.context.fill();
};

const useImageFromConfig = (config) => {
	const imageRef = useRef(/** @type {HTMLImageElementReady|undefined} */(undefined));

	const configReplacementImageUrl = config?.replacement?.mode === KeyReplacementMode.IMAGE
		? config.replacement.imageUrl
		: undefined;

	useEffect(() => {
		if (!configReplacementImageUrl) {
			imageRef.current = undefined;
			return;
		}

		imageRef.current = /** @type {HTMLImageElementReady} */(new Image());
		imageRef.current.crossOrigin = 'Anonymous';
		imageRef.current.src = getCrossOriginFileUrl(configReplacementImageUrl);
		imageRef.current.isReady = false;
	}, [configReplacementImageUrl]);

	return imageRef;
};

const ANIMATION_TIMEOUT = Math.floor(1000 / 60);

/**
 * @param {{
 *   config: KeyConfig,
 * 	 configOverride: KeyConfig,
 *   videoSourceTrack: MediaStreamTrack,
 *   options: {
 *     fps: number,
 *	   outputWidth: number,
 *	   outputHeight: number,
 *   },
 * }} param0
 */
export const useMediaKeyPerformer = ({
	config,
	configOverride,
	videoSourceTrack,
	options,
}) => {
	const [keyVideoTrack, setKeyVideoTrack] = useState(
		/** @type {MediaStreamKeyTrack|undefined} */(undefined),
	);

	const {
		fps = USER_MAX_FPS,
		outputWidth,
		outputHeight,
	} = options ?? {};

	const output = useCanvasAndContext();
	const outputFirstpass = useCanvasAndContext();
	const segmentationMask = useCanvasAndContext(true);
	const person = useCanvasAndContext(true);
	const background = useCanvasAndContext(true, { alpha: false });
	const backgroundFirstpass = useCanvasAndContext(true, { alpha: false });
	const rgb = useCanvasAndContext(true);
	const source = useConst(() => document.createElement('video'));

	const bodyPix = useRef(/** @type {BodyPixModule|undefined}} */(undefined));
	const bodyPixNet = useRef(/** @type {BodyPix|undefined} */(undefined));
	const id = useRef(/** @type {number|undefined} */(undefined));

	const configRef = useRef(config);
	const configOverrideRef = useRef(configOverride);

	useEffect(() => {
		configRef.current = config;
		configOverrideRef.current = configOverride;
	});

	const img = useImageFromConfig(config);
	const imgOverride = useImageFromConfig(configOverride);

	const isKeyEnabled = (
		(
			!!config?.detection.mode
			&& config.detection.mode !== KeyDetectionMode.DISABLED
		)
		|| (
			!!configOverride?.detection.mode
			&& configOverride.detection.mode !== KeyDetectionMode.DISABLED
		)
	);

	useEffect(() => {
		let isActive = false;

		const doDetectAI = async (src) => (
			/** @type {BodyPix} */(bodyPixNet.current).segmentPerson(src, { internalResolution: 'low' })
		);

		const doDetectRGB = async (src, detectionConf) => {
			rgb.context.clearRect(0, 0, rgb.canvas.width, rgb.canvas.height);
			rgb.context.drawImage(src, 0, 0, rgb.canvas.width, rgb.canvas.height);

			const frameRgb = rgb.context.getImageData(
				0,
				0,
				rgb.canvas.width,
				rgb.canvas.height,
			);
			const pixels = frameRgb.data;
			for (let i = 0; i < pixels.length; i += 4) {
				const [r, g, b] = [pixels[i], pixels[i + 1], pixels[i + 2]];
				if (replacePixel({ r, g, b }, detectionConf)) {
					frameRgb.data[i] = 0;
					frameRgb.data[i + 1] = 0;
					frameRgb.data[i + 2] = 0;
					frameRgb.data[i + 3] = 0;
				} else {
					frameRgb.data[i] = 0;
					frameRgb.data[i + 1] = 0;
					frameRgb.data[i + 2] = 0;
					frameRgb.data[i + 3] = 255;
				}
			}

			return frameRgb;
		};

		const doDetect = async (src, detectionConf) => {
			if (detectionConf.mode === KeyDetectionMode.AI) {
				return doDetectAI(src);
			}
			if (detectionConf.mode === KeyDetectionMode.RGB) {
				return doDetectRGB(src, detectionConf);
			}
			return undefined;
		};

		let then = 0;

		const doReplaceAi = (src, segmentation, conf, data) => {
			const { blur, edgeBlur, mode: replacementMode } = conf.replacement;

			if (replacementMode === KeyReplacementMode.BLUR) {
				/** @type {BodyPixModule} */(bodyPix.current).drawBokehEffect(
					(data.output || output).canvas,
					src,
					segmentation,
					blur,
					edgeBlur,
				);
				return;
			}

			const backgroundDarkeningMask = /** @type {BodyPixModule} */(bodyPix.current).toMask(
				segmentation,
				{ r: 0, g: 0, b: 0, a: 255 },
				{ r: 0, g: 0, b: 0, a: 0 },
				false,
			);

			runPostProcessing(
				src,
				backgroundDarkeningMask,
				conf.replacement,
				{
					background: data.background || background,
					img: data.img.current,
					output: data.output || output,
					person,
					segmentationMask,
				},
			);
		};

		const doReplaceRgb = (src, frameRgb, conf, data) => {
			runPostProcessing(
				src,
				frameRgb,
				conf.replacement,
				{
					background: data.background || background,
					img: data.img.current,
					output: data.output || output,
					person,
					segmentationMask,
				},
			);
		};

		const doReplace = async (
			src,
			detectionResult, // segmentation or frameRgb
			conf,
			data,
		) => {
			const { mode: detectionMode } = conf.detection;

			if (detectionMode === KeyDetectionMode.AI) {
				return doReplaceAi(src, detectionResult, conf, data);
			}

			return doReplaceRgb(src, detectionResult, conf, data);
		};

		const perform = () => {
			if (!isActive) return;
			const callPerform = () => {
				if (!isActive) return;
				// Cannot use raf or requestVideoFrameCallback because
				// they are paused when the tab is not visible
				// id.current = window.requestAnimationFrame(() => { perform(); });
				// id.current = source.requestVideoFrameCallback(() => {
				// 	if (!isActive) return;
				// 	perform();
				// });
				id.current = window.setTimeout(() => { perform(); }, ANIMATION_TIMEOUT);
			};
			const start = performance.now();
			const delta = start - then;
			if (delta < 1000 / fps) {
				callPerform();
				return;
			}
			then = start - (delta % (1000 / fps));

			// User config
			const firstPassConfig = configRef.current;
			// Config override by studio scene
			const secondPassConfig = configOverrideRef.current;

			const firstPassDetection = firstPassConfig?.detection;
			const secondPassDetection = secondPassConfig?.detection;

			const performPasses = async () => {
				let src = source;
				let segmentation;

				const hasSecondPass = (
					secondPassDetection
					&& secondPassDetection.mode !== KeyDetectionMode.DISABLED
				);

				if (
					firstPassDetection
					&& firstPassDetection.mode !== KeyDetectionMode.DISABLED
				) {
					segmentation = await doDetect(src, firstPassDetection);
					if (!isActive) return;
					const out = hasSecondPass ? outputFirstpass : output;
					doReplace(
						src,
						segmentation,
						firstPassConfig,
						{
							background: backgroundFirstpass,
							img,
							output: out,
						},
					);
					src = out.canvas;
				}

				if (
					secondPassDetection
					&& secondPassDetection.mode !== KeyDetectionMode.DISABLED
				) {
					// Reuse first segmentation if the detections modes are the same
					if (firstPassDetection?.mode !== secondPassDetection.mode) {
						segmentation = await doDetect(src, secondPassDetection);
					}
					if (!isActive) return;
					doReplace(
						src,
						segmentation,
						secondPassConfig,
						{
							background,
							img: imgOverride,
						},
					);
				}
			};

			performPasses().then(() => {
				callPerform();
			});
		};

		const startPerform = () => {
			// Cannot use raf or requestVideoFrameCallback because
			// they are paused when the tab is not visible
			// id.current = window.requestAnimationFrame(() => { perform(); });
			// id.current = source.requestVideoFrameCallback(() => {
			// 	if (!isActive) return;
			// 	perform();
			// });
			id.current = window.setTimeout(() => { perform(); }, ANIMATION_TIMEOUT);
			const [track] = /** @type {[MediaStreamKeyTrack]}*/(
				/** @type {HTMLCanvasElement} */(output.canvas).captureStream(fps).getVideoTracks()
			);
			track.isKey = true;
			setKeyVideoTrack(track);
		};

		const loadBodyPix = async () => {
			if (bodyPix.current) return;
			try {
				bodyPix.current = await importTensorFlow();
				const net = await bodyPix.current.load({
					architecture: 'MobileNetV1',
					outputStride: 16,
					multiplier: 0.5,
					quantBytes: 2,
				});
				bodyPixNet.current = net;
			} catch (err) {
				console.error(err);
			}
		};

		const init = () => {
			isActive = true;

			const sourceStream = new MediaStream();

			if (videoSourceTrack) sourceStream.addTrack(videoSourceTrack);

			source.onloadeddata = async () => {
				source.onloadeddata = undefined;

				if (!isActive) return;

				source.width = source.videoWidth;
				source.height = source.videoHeight;

				const width = Math.min(outputWidth || source.videoWidth, source.videoWidth);
				const height = Math.min(outputHeight || source.videoHeight, source.videoHeight);

				output.canvas.width = width;
				output.canvas.height = height;
				outputFirstpass.canvas.width = width;
				outputFirstpass.canvas.height = height;
				rgb.canvas.width = width;
				rgb.canvas.height = height;

				if (img.current) {
					img.current.isReady = false;
				}

				if (imgOverride.current) {
					imgOverride.current.isReady = false;
				}

				await loadBodyPix();
				if (!isActive) return;

				startPerform();
			};

			source.srcObject = sourceStream;
			source.play();
		};

		if (
			isKeyEnabled
			&& videoSourceTrack
		) {
			init();
		}

		return () => {
			isActive = false;
			source.onloadeddata = undefined;
			source.pause();
			setKeyVideoTrack(undefined);
			if (id.current) cancelAnimationFrame(id.current);
		};
	}, [
		background,
		backgroundFirstpass,
		fps,
		img,
		imgOverride,
		isKeyEnabled,
		output,
		outputFirstpass,
		outputHeight,
		outputWidth,
		person,
		rgb,
		segmentationMask,
		source,
		videoSourceTrack,
	]);

	return useMemo(
		() => ({
			config,
			keyVideoTrack,
			output,
			videoSourceTrack,
		}),
		[
			config,
			keyVideoTrack,
			output,
			videoSourceTrack,
		],
	);
};
