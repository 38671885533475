import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Toast,
	ToastBody,
	ToastHeader,
} from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { Sound, useSound } from '../../Sound/Provider';
import { getFileUrl, AVATAR_SIZE } from '../../../lib/file';
import defaultAvatar from '../../../images/default-avatar.png';
import Hexagon from '../../Hexagon/Hexagon';
import { NotificationType } from '../../../lib/NotificationType';
import NotificationToastDonation from './Donation';
import NotificationToastSponsorAd from './SponsorAd';
import NotificationToastInvitation from './Invitation';
import NotificationToastVideo from './Video';
import { NotificationToastInformation } from './Information';
import { NotificationToastDonationThankYou } from './DonationThankYou';

import './Toast.scss';

const NotificationComponents = {
	[NotificationType.DONATION]: NotificationToastDonation,
	[NotificationType.PUBLIC_STUDIO_START_SOON]: NotificationToastInvitation,
	[NotificationType.STUDIO_HOST_REMINDER]: NotificationToastInvitation,
	[NotificationType.STUDIO_IDLE]: NotificationToastInvitation,
	[NotificationType.STUDIO_INVITE]: NotificationToastInvitation,
	[NotificationType.INFORMATION]: NotificationToastInformation,
	[NotificationType.VIDEO]: NotificationToastVideo,
	[NotificationType.STUDIO_ENDING]: NotificationToastInformation,
	[NotificationType.SPONSOR_AD]: NotificationToastSponsorAd,
	[NotificationType.DONATION_THANK_YOU]: NotificationToastDonationThankYou,
};

const NotificationToast = ({ notification, onClose, closeToast }) => {
	const { t } = useTranslation();
	const { playSound } = useSound();

	useEffect(
		() => () => { onClose(notification); },
		[onClose, notification],
	);

	useEffect(() => {
		playSound(Sound.NOTIFICATION);
	}, [playSound]);

	const hostAvatar = notification.sender.avatar ? getFileUrl(
		{ name: notification.sender.avatar },
		AVATAR_SIZE.width,
		AVATAR_SIZE.height,
	) : defaultAvatar;

	const Component = NotificationComponents[notification.type];

	return (
		<Toast className="NotificationToast w-100">
			<ToastHeader
				icon={(
					<Hexagon className="d-flex d-30 align-items-center justify-content-center">
						<img alt={t('Toast.Donation.avatar')} className="img-fit-container" src={hostAvatar} />
					</Hexagon>
				)}
			>
				{notification.sender?.nickname}
			</ToastHeader>
			<ToastBody className="text-black">
				<Component closeToast={closeToast} notification={notification} />
			</ToastBody>
			<Button
				color="neutral-dark"
				className="NotificationToast_close position-absolute bg-transparent p-0 m-2"
				onClick={closeToast}
			>
				<span className="btn-wrapper--icon">
					<FaTimes />
				</span>
			</Button>
		</Toast>
	);
};

NotificationToast.propTypes = {
	closeToast: PropTypes.func,
	notification: PropTypes.shape({
		sender: PropTypes.shape({
			avatar: PropTypes.string,
			nickname: PropTypes.string.isRequired,
		}).isRequired,
		type: PropTypes.oneOf(Object.values(NotificationType)).isRequired,
	}).isRequired,
	onClose: PropTypes.func.isRequired,
};

NotificationToast.defaultProps = {
	closeToast: () => {},
};

export default NotificationToast;
