// @ts-check

import Rollbar from 'rollbar';

const {
	VITE_BUILD_ID,
	VITE_ROLLBAR_ACCESS_TOKEN,
	VITE_ROLLBAR_ENABLED,
	VITE_STAGE,
	VITE_VERSION,
} = import.meta.env;

// eslint-disable-next-line camelcase
const code_version = `${VITE_VERSION}/${VITE_BUILD_ID}`;
const isEnvironmentEnabled = VITE_ROLLBAR_ENABLED === 'true';
const environment = VITE_STAGE || import.meta.env.MODE;

export const rollbar = new Rollbar({
	accessToken: VITE_ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: isEnvironmentEnabled, // enabled before cookies approval to avoid loosing early errors
	payload: {
		client: { javascript: { code_version } },
		environment,
	},
});

window.rollbar = rollbar;

export const enableRollbar = () => {
	rollbar.configure({ enabled: isEnvironmentEnabled });
};

export const disableRollbar = () => {
	rollbar.configure({ enabled: false });
};

export const setSessionId = (sessionId) => rollbar.configure({ sessionId });

export const setUser = (user) => rollbar.configure({ payload: { user } });
