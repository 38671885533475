import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Col, Row } from 'reactstrap';
import { useQueryClient } from 'react-query';
import { useCapturePaypalOrder, useCreatePaypalOrder } from '../../../../api-hooks/membership/paypal';
import { GOALS_QUERY_KEYS } from '../../../../api-hooks/channel/goals';

export const OrderBundleType = {
	PI_POINTS: 'PI_POINTS',
	AD_CREDITS: 'AD_CREDITS',
	RESOURCE: 'RESOURCE',
	DONATION: 'DONATION',
};

const { VITE_PAYPAL_CLIENT_ID } = import.meta.env;

export const PaypalOrderButton = ({
	amount,
	orderBundleType,
	onOrderPaymentComplete,
	channelId,
	includeFee,
	selectedResource,
	goalId,
}) => {
	const { mutateAsync: createOrderAsync } = useCreatePaypalOrder();
	const { mutate: captureOrder } = useCapturePaypalOrder();
	const queryClient = useQueryClient();

	const handleCreateOrder = useCallback(async () => {
		const orderData = await createOrderAsync({
			amount,
			orderBundleType,
			includeFee,
			resource: selectedResource,
			goalId,
			channelId: orderBundleType === OrderBundleType.DONATION
				? channelId
				: undefined,
		});

		return orderData.id;
	}, [
		amount,
		createOrderAsync,
		orderBundleType,
		channelId,
		includeFee,
		selectedResource,
		goalId,
	]);

	const handleApproveOrder = useCallback((data) => {
		captureOrder(
			{ orderId: data.orderID },
			{
				onSuccess: () => {
					onOrderPaymentComplete();
					queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchOneGoalById(goalId));
					queryClient.invalidateQueries(GOALS_QUERY_KEYS.fetchGoalsByChannelId(channelId));
				},
			},
		);
	}, [captureOrder, channelId, goalId, onOrderPaymentComplete, queryClient]);

	return (
		<Row className="mt-4">
			<Col className="w-100 d-flex justify-content-center pb-2">
				<div className="p-2 bg-white rounded">
					<PayPalScriptProvider
						options={{
							'client-id': VITE_PAYPAL_CLIENT_ID,
							components: 'buttons',
						}}
					>
						<PayPalButtons
							style={{
								label: 'pay',
								color: 'blue',
							}}
							createOrder={handleCreateOrder}
							onApprove={handleApproveOrder}
							forceReRender={[handleCreateOrder]}
						/>
					</PayPalScriptProvider>
				</div>
			</Col>
		</Row>
	);
};

PaypalOrderButton.propTypes = {
	amount: PropTypes.number,
	goalId: PropTypes.string,
	onOrderPaymentComplete: PropTypes.func.isRequired,
	orderBundleType: PropTypes.string,
	channelId: PropTypes.string,
	includeFee: PropTypes.bool,
	selectedResource: PropTypes.shape({
		id: PropTypes.string,
		type: PropTypes.string,
	}),
};

PaypalOrderButton.defaultProps = {
	amount: 0,
	goalId: undefined,
	orderBundleType: OrderBundleType.PI_POINTS,
	channelId: undefined,
	includeFee: false,
	selectedResource: undefined,
};
