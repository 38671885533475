// @ts-check

import { useCallback, useMemo } from 'react';

import { useSelector } from '../components/ReactVideo/Provider';
import { selectTalkbackStreamsPublications } from '../store/selectors/publications';
import { selectTalkbackSources } from '../store/selectors/sources';
import { useMediaTalkback } from '../components/Media/Talkback/Talkback';

/**
 * @param {{
 *  hashtag: string,
 * }} params
 * @returns {{
 * 	talkbackReceivingSources: import('../store/selectors/sources').ParticipantSource[],
 * 	talkbackSendingSources: import('../store/selectors/sources').ParticipantSource[],
 * 	talkbackStreamsPublications: import('../lib/store/publication').StreamPublication[],
 * }}
 */
export const useTalkbackReceiver = ({ hashtag }) => {
	const talkbackStreamsPublications = useSelector(selectTalkbackStreamsPublications);
	const talkbackSources = useSelector((state) => selectTalkbackSources(state, { hashtag }));

	const talkbackReceivingSources = useMemo(
		() => (talkbackSources || []).filter(({ own }) => !own), [talkbackSources],
	);
	const talkbackSendingSources = useMemo(
		() => (talkbackSources || []).filter(({ own }) => own), [talkbackSources],
	);

	return useMemo(() => ({
		talkbackReceivingSources,
		talkbackSendingSources,
		talkbackStreamsPublications: talkbackStreamsPublications || [],
	}), [
		talkbackReceivingSources,
		talkbackSendingSources,
		talkbackStreamsPublications,
	]);
};

export const useTalkbackSender = () => {
	const {
		configId,
		isDisconnecting,
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		userAudioRequestError,
	} = useMediaTalkback();

	return useMemo(() => ({
		configId,
		isDisconnecting,
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		userAudioRequestError,
	}), [
		configId,
		isDisconnecting,
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		userAudioRequestError,
	]);
};

/** @enum {string} */
export const TalkbackStatus = {
	READY: 'READY',
	CONNECTING: 'CONNECTING',
	CONNECTED: 'CONNECTED',
	DISCONNECTING: 'DISCONNECTING',
};

/**
 * @param {{
*  hashtag: string,
* }} params
* @returns {{
* 	senderStatus: TalkbackStatus,
* 	receiverStatus: TalkbackStatus,
* }}
*/
export const useTalkbackStatus = ({ hashtag }) => {
	const {
		isDisconnecting: isSenderDisconnecting,
		recipientUsers,
	} = useMediaTalkback();

	const {
		talkbackSendingSources,
		talkbackStreamsPublications,
	} = useTalkbackReceiver({ hashtag });

	const isSenderActive = recipientUsers.length > 0;
	const isSenderConnected = talkbackSendingSources.length > 0;
	const isSenderConnecting = isSenderActive && !isSenderConnected;

	const getSenderStatus = useCallback(() => {
		if (isSenderDisconnecting) return TalkbackStatus.DISCONNECTING;
		if (isSenderConnecting) return TalkbackStatus.CONNECTING;
		if (isSenderConnected) return TalkbackStatus.CONNECTED;
		return TalkbackStatus.READY;
	}, [
		isSenderConnecting,
		isSenderConnected,
		isSenderDisconnecting,
	]);

	const getReceiverStatus = useCallback(() => {
		if (talkbackStreamsPublications.length > 0) return TalkbackStatus.CONNECTED;
		return TalkbackStatus.READY;
	}, [talkbackStreamsPublications]);

	return useMemo(() => ({
		receiverStatus: getReceiverStatus(),
		senderStatus: getSenderStatus(),
	}), [
		getReceiverStatus,
		getSenderStatus,
	]);
};
