// @ts-check

import { api } from './api';

export const API_SEARCH_PATH = '/search';

/**
 * @import { PaginatedResult } from './helpers';
 * @import {
 * 	ESChannelResult,
 * 	ESOrganizationResult,
 * 	ESVodResult,
 * } from './elasticsearch';
 * @import { AxiosResponse } from 'axios';
 * @import { IUserSuggestionDto } from './search.dto';
 * */

/**
 * @typedef {{
* 	q?: string,
* 	filter?: string,
* 	sort?: string,
 * }} APISearchParams
 */

/**
 * @param {string} searchTerm
 * @param {Omit<APISearchParams, 'q'>} opts
 * @returns {Promise<AxiosResponse<PaginatedResult<
 * 	ESChannelResult | ESOrganizationResult | ESVodResult
 * >>>}
 */
export const fetchSearchResults = async (searchTerm, opts) => api.get(
	`${API_SEARCH_PATH}/q/?q=${searchTerm}`,
	{ params: opts },
);

/**
 * @param {string} searchTerm
 * @returns {Promise<AxiosResponse<string[]>>}
*/
export const fetchSearchSuggestions = async (searchTerm) => api.get(
	`${API_SEARCH_PATH}/s/?q=${searchTerm}`,
);

/**
 * @param {string} searchTerm
 * @param {{
 * 		excludeMe?: boolean,
 * 		friendsOnly?: boolean,
 * 		operatorsOnly?: boolean,
 * 		excludeOperators?: boolean,
 * 		excludeSpecificUsers?: string[],
 * }} opts
 * @returns {Promise<AxiosResponse<IUserSuggestionDto[]>>}
 */
export const fetchUserSuggestions = async (searchTerm, opts) => api.get(
	`${API_SEARCH_PATH}/u/?q=${searchTerm}`,
	{ params: opts },
);

/** @typedef {import('./channel/gfx.dto').IGfxDto} IGfxDto */
/** @typedef {import('./channel/images.dto').IImageDto} IImageDto */
/** @typedef {import('./channel/playlists.dto').IPlaylistDto} IPlaylistDto */
/** @typedef {import('./channel/stream.dto').IStreamDto} IStreamDto */
/** @typedef {import('./channel/videos.dto').IVideoPublicDto} IVideoPublicDto */

/**
 * @typedef {import('../components/Studio/Search/helper')
 * 	.SearchLibraryContentType} SearchLibraryContentType
 * */

/**
 * @param {string} searchTerm
 * @param {{
 * 	channelId: string,
 *  libraryFilters: SearchLibraryContentType[],
 * }} opts
 * @returns {Promise<AxiosResponse<
 * 	((IGfxDto | IImageDto | IPlaylistDto | IStreamDto | IVideoPublicDto) & {
 * 	contentType: SearchLibraryContentType,
 *  isBeeyouLibrary: boolean,
 * })[]
 * >>}
 */
export const fetchLibrarySuggestions = async (searchTerm, opts) => api.get(
	`${API_SEARCH_PATH}/l/?q=${searchTerm}`,
	{ params: opts },
);
