/* eslint-disable react/prop-types */
// @ts-check

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useDeviceStatusFromConfig } from '../User';

/** @import { UserMediaStreamTrack } from '../User'; */

/**
 * @typedef {UserMediaStreamTrack & {
 * 	originalTrackId?: string,
 * }} TalkbackMediaStreamTrack
 */

/**
 * @typedef {{
 * 	_id: string,
 * 	nickname: string,
 *  group?: {
 * 		_id: string,
 * 		label: string,
 * 	},
 * }} TalkbackRecipientUser
 */

/**
 * @typedef {{
 *  configId: number,
 *	isDisconnecting: boolean,
 *	recipientUsers: TalkbackRecipientUser[],
 * 	startTalkbacks: (users: TalkbackRecipientUser[]) => void,
 * 	stopAllTalkbacks: () => void,
 * 	stopTalkbacks: (param: { userId?: string, groupId?: string }) => void,
 *	talkbackActiveTrack: TalkbackMediaStreamTrack?,
 *	userAudioRequestError?: Error,
 * }} IMediaTalkbackContext
 */

const MediaTalkbackContext = createContext(/** @type {IMediaTalkbackContext} */({}));

export const useMediaTalkback = () => useContext(MediaTalkbackContext);

const CONFIG_ID = 0;

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} MediaTalkbackProps
 */

export const MediaTalkback = (
	/** @type {MediaTalkbackProps} */
	{
		children,
	},
) => {
	const {
		deviceTrack,
		toggleInputDevice,
	} = useDeviceStatusFromConfig(CONFIG_ID, 'audioinput');

	const audioWasOffBeforeTalkback = useRef(false);
	const [stopTalkbackWhenAudioOff, setStopTalkbackWhenAudioOff] = useState(false);

	const [recipientUsers, setRecipientUsers] = useState(
		/** @type {TalkbackRecipientUser[]} */([]),
	);

	const talkbackTrackRef = useRef(/** @type {TalkbackMediaStreamTrack?}*/(null));
	const talkbackActiveTrack = useMemo(() => {
		// Stop existing talkback track when recipientUsers is empty
		if (!recipientUsers.length) {
			talkbackTrackRef.current?.stop();
			talkbackTrackRef.current = null;
			return null;
		}

		if (talkbackTrackRef.current) return talkbackTrackRef.current;

		const defaultMicrophone = deviceTrack;
		if (!defaultMicrophone) return null;

		/** @type {TalkbackMediaStreamTrack} */
		const talkbackTrack = defaultMicrophone.clone();
		talkbackTrack.originalTrackId = defaultMicrophone.id;
		talkbackTrackRef.current = talkbackTrack;
		return talkbackTrack;
	}, [deviceTrack, recipientUsers]);

	const startTalkbacks = useCallback((
		/** @type {TalkbackRecipientUser[]} */newUsers,
	) => {
		setRecipientUsers((prev) => {
			// Only add users that are not already talkbacking
			const newUserMap = newUsers.reduce((acc, u) => {
				acc[u._id] = u;
				return acc;
			}, /** @type {{ [key: string]: TalkbackRecipientUser }} */({}));

			prev.forEach((u) => delete newUserMap[u._id]);

			return [...prev, ...(Object.values(newUserMap))];
		});

		const alreadyOn = recipientUsers.length > 0;
		// If talkback is starting, check if audio is off and turn it on
		if (!alreadyOn) {
			if (!deviceTrack) {
				toggleInputDevice();
				audioWasOffBeforeTalkback.current = true;
			} else {
				audioWasOffBeforeTalkback.current = false;
			}
		}
	}, [deviceTrack, toggleInputDevice, recipientUsers]);

	const stopTalkbacks = useCallback((
		/** @type {{ userId?: string, groupId?: string }} */{
			userId, groupId,
		},
	) => {
		if (!userId && !groupId) throw new Error('Either userId or groupId must be provided');
		if (userId && groupId) throw new Error('Only one of userId or groupId can be provided');

		setRecipientUsers((prev) => {
			/** @type {TalkbackRecipientUser[]} */
			let updatedRecipients = [];

			if (userId) updatedRecipients = prev.filter((u) => u._id !== userId);
			else if (groupId) updatedRecipients = prev.filter((u) => u.group?._id !== groupId);

			if (!updatedRecipients.length && audioWasOffBeforeTalkback.current) {
				toggleInputDevice();
				setStopTalkbackWhenAudioOff(true);
				return prev;
			}

			return updatedRecipients;
		});
	}, [toggleInputDevice]);

	const stopAllTalkbacks = useCallback(() => {
		setRecipientUsers([]);
	}, []);

	// Use to wait for audio to be turned off before stopping talkback
	useEffect(() => {
		if (stopTalkbackWhenAudioOff && !deviceTrack) {
			setStopTalkbackWhenAudioOff(false);
			setRecipientUsers([]);
		}
	}, [deviceTrack, stopTalkbackWhenAudioOff]);

	const value = useMemo(() => ({
		configId: CONFIG_ID,
		isDisconnecting: stopTalkbackWhenAudioOff,
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		talkbackActiveTrack,
	}), [
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		stopTalkbackWhenAudioOff,
		talkbackActiveTrack,
	]);

	return (
		<MediaTalkbackContext.Provider value={value}>
			{children}
		</MediaTalkbackContext.Provider>
	);
};
