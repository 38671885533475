// @ts-check

import { api } from '../api';

/**
 * @import { AxiosResponse } from 'axios'
 * @import { IGroupOwnDto } from './groups.dto'
 * */

export const API_CHANNEL_GROUPS_PATH = '/channel/groups';

/** @returns {Promise<AxiosResponse<IGroupOwnDto[]>>} */
export const fetchGroups = () => api.get(`${API_CHANNEL_GROUPS_PATH}`);

/**
 * @param {string} id
* @returns {Promise<AxiosResponse<IGroupOwnDto>>}
*/
export const fetchGroup = (id) => api.get(`${API_CHANNEL_GROUPS_PATH}/${id}`);

/**
 * @param {{
 *  label: string,
 * 	users?: string[],
 * }} group
* @returns {Promise<AxiosResponse<IGroupOwnDto>>}
*/
export const createGroup = (group) => api.post(
	`${API_CHANNEL_GROUPS_PATH}/`,
	group,
);

/**
 * @param {{
 *  _id: string,
 * 	label: string,
 * 	users?: string[],
 * }} group
* @returns {Promise<AxiosResponse<IGroupOwnDto>>}
*/
export const updateGroup = (group) => api.put(
	`${API_CHANNEL_GROUPS_PATH}/${group._id}`,
	group,
);

/**
 * @param {string} groupId
* @returns {Promise<AxiosResponse<void>>}
*/
export const deleteGroup = (groupId) => api.delete(
	`${API_CHANNEL_GROUPS_PATH}/${groupId}`,
);

/**
 * @param {string} groupId
* @returns {Promise<AxiosResponse<void>>}
*/
export const leaveGroup = (groupId) => api.post(
	`${API_CHANNEL_GROUPS_PATH}/${groupId}/leave`,
);
