// copied from https://github.com/twilio/twilio-webrtc.js/blob/master/lib/getusermedia.js
export default async function getUserMedia(constraints) {
	if (typeof navigator === 'object'
		&& typeof navigator.mediaDevices === 'object'
		&& typeof navigator.mediaDevices.getUserMedia === 'function') {
		constraints = constraints || { audio: true, video: true };
		return navigator.mediaDevices.getUserMedia(constraints);
	}
	return Promise.reject(new Error('getUserMedia is not supported'));
}
