// @ts-check

import { createContext, useContext } from 'react';

/**
 * @typedef {{
* 	_id: string,
* 	channelId: string,
* 	hashtag: string,
* 	hasLeft: boolean,
* 	nickname: string,
* 	organization: string,
* 	picture?: string,
* 	role: import('../../../lib/ResourceAccessRole').ResourceAccessRole,
* 	userRole: import('../../Authentication/Authentication').Role,
* 	uuid: string,
*   isBanned?: boolean,
* }} ConnectedClient
* */

/**
 * @typedef {{
 *	connectedClients: ConnectedClient[],
 *	handleEventConnectionNew: (client: ConnectedClient) => void,
 *	handleEventConnectionLeft: (client: ConnectedClient, forceClear?: boolean) => void,
 *	handleEventConnectionAll: (clients: ConnectedClient[]) => void,
 *	notLoggedInClientsCount: number,
* }} IChannelConnectionContext
*/

export const ChannelConnectionContext = createContext(
	/** @type {IChannelConnectionContext | undefined} */(undefined),
);

export const useChannelConnection = () => {
	const channelConnectionContext = useContext(ChannelConnectionContext);
	// type guard (removes undefined type)
	if (!channelConnectionContext) {
		throw new Error('useChannelConnection must be used within a ChannelConnectionProvider');
	}
	return channelConnectionContext;
};
