import { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { Button } from 'reactstrap';

import { isMobileOrIpad } from '../../lib/userAgent';
import volumeSliderImage from './slider.jpg';

import './PlayerLiveVuMeter.scss';

const ANALYZER_INTERVAL_MS = 100;

export const PlayerLiveVuMeter = ({
	className,
	disabled,
	handleMute,
	isMuted,
	isOnLive,
	isParticipant,
	mediastream,
	onVolumeChange,
	showSlider,
	volume,
}) => {
	const [showVolume, setShowVolume] = useState(false);
	const [newVolume, setNewVolume] = useState(volume);
	useEffect(() => { setNewVolume(volume); }, [volume]);
	const meterLeftRef = useRef();
	const meterRightRef = useRef();

	const wrapperRef = useRef();

	const audioTrack = mediastream?.getAudioTracks()[0];

	const sliderY = 100 - ((newVolume / 150) * 100);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateVolume = useCallback(debounce((vol) => {
		if (isParticipant && Number.isFinite(vol)) {
			onVolumeChange(vol);
		}
	}, 1000), [onVolumeChange]);

	const handleMouseMove = useCallback((e) => {
		if (isMobileOrIpad && e.cancelable) e.preventDefault();
		const mouseY = isMobileOrIpad ? e.touches[0].clientY : e.clientY;
		const { y, height } = wrapperRef.current.getBoundingClientRect();

		let mouseYToPercentage = ((mouseY - y) / height) * 100;
		let rangeTo150 = 150 - (mouseYToPercentage * 150) / 100;

		if (mouseYToPercentage <= 0) {
			mouseYToPercentage = 0;
		} else if (mouseYToPercentage > 100) {
			mouseYToPercentage = 100;
		}

		rangeTo150 = Math.max(0, Math.min(rangeTo150, 150));

		updateVolume(rangeTo150);
		setNewVolume(rangeTo150);
	}, [updateVolume]);

	const handleMouseUp = useCallback(() => {
		setShowVolume(false);
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
		document.removeEventListener('touchmove', handleMouseMove);
		document.removeEventListener('touchend', handleMouseUp);
	}, [handleMouseMove]);

	const onMouseDown = useCallback(() => {
		setShowVolume(true);
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
		document.addEventListener('touchmove', handleMouseMove, { passive: false });
		document.addEventListener('touchend', handleMouseUp);
	}, [handleMouseMove, handleMouseUp]);

	useEffect(() => () => {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('touchmove', handleMouseMove);
	}, [handleMouseMove]);

	const updateMeters = useCallback((volumes) => {
		const clampVolumeDisplayChannels = (volumeLevel) => {
			if (disabled) return 0;

			if (volumeLevel > (100 - sliderY) && isParticipant) return (100 - sliderY);
			if (volumeLevel < 0) return 0;
			if (volumeLevel > 100) return 100;
			return volumeLevel;
		};

		if (meterLeftRef.current) {
			meterLeftRef.current.style.height = `${100 - clampVolumeDisplayChannels(volumes.left)}%`;
		}
		if (meterRightRef.current) {
			meterRightRef.current.style.height = `${100 - clampVolumeDisplayChannels(volumes.right)}%`;
		}
	}, [disabled, isParticipant, sliderY]);

	useEffect(() => {
		if (!audioTrack) return undefined;

		const onTrackEnded = () => {
			const meterLeft = meterLeftRef.current;
			const meterRight = meterRightRef.current;
			if (meterLeft) meterLeft.style.height = '100%';
			if (meterRight) meterRight.style.height = '100%';
		};
		audioTrack.addEventListener('ended', onTrackEnded);

		const localMediaStream = new MediaStream([audioTrack]);
		const audioContext = new (window.AudioContext || window.webkitAudioContext)();
		const mediaStreamSource = audioContext.createMediaStreamSource(localMediaStream);

		const splitter = audioContext.createChannelSplitter(2);
		mediaStreamSource.connect(splitter);

		const analyserLeft = audioContext.createAnalyser();
		const analyserRight = audioContext.createAnalyser();
		analyserLeft.fftSize = 128;
		analyserRight.fftSize = 128;

		splitter.connect(analyserLeft, 0);
		splitter.connect(analyserRight, 1);

		const getVolume = (analyser) => {
			const bufferLength = analyser.frequencyBinCount;
			const dataArray = new Uint8Array(bufferLength);

			analyser.getByteFrequencyData(dataArray);
			let sum = 0;
			for (let i = 0; i < bufferLength; i += 1) {
				sum += dataArray[i];
			}
			return (sum / bufferLength);
		};

		const interval = setInterval(() => {
			const volumeLeft = getVolume(analyserLeft);
			const volumeRight = getVolume(analyserRight);
			if (audioTrack
				&& (audioTrack.getSettings().channelCount === 1
				|| !audioTrack.getSettings().channelCount)
			) {
				//clone the volume to both channels
				updateMeters({ left: volumeLeft, right: volumeLeft });
			} else {
				updateMeters({ left: volumeLeft, right: volumeRight });
			}
		}, ANALYZER_INTERVAL_MS);

		return () => {
			audioTrack.removeEventListener('ended', onTrackEnded);
			clearInterval(interval);
			if (audioContext.state !== 'closed') {
				audioContext.close().catch((error) => {
					// eslint-disable-next-line no-console
					console.error('Error closing AudioContext:', error);
				});
			}
		};
	}, [isParticipant, audioTrack, updateMeters]);

	let muteButtonColor = isOnLive ? 'neutral-danger' : 'neutral-secondary';
	if (disabled) {
		muteButtonColor = 'black';
	}

	return (
		<div className={clsx('d-flex flex-column', className)}>
			<div className="d-flex h-100 position-relative">
				<div className="d-flex">
					<div className={clsx('PlayerLiveVuMeter_bar', { PlayerLiveVuMeter_meter: !disabled })} />
					<div
						className={clsx('PlayerLiveVuMeter_bar position-absolute bg-black', { 'opacity-3': disabled })}
						ref={meterLeftRef}
						style={{ height: '100%' }}
					/>
				</div>
				<div className="border border-transparent" />
				<div className="d-flex">
					<div className={clsx('PlayerLiveVuMeter_bar', { PlayerLiveVuMeter_meter: !disabled })} />
					<div
						className={clsx('PlayerLiveVuMeter_bar position-absolute bg-black', { 'opacity-3': disabled })}
						ref={meterRightRef}
						style={{ height: '100%' }}
					/>
				</div>
				{showSlider && !disabled && (
					<div
						className={clsx('position-absolute w-50 h-100', newVolume === 0 ? 'mb-4' : 'mb-1')}
						ref={wrapperRef}
						onMouseDown={onMouseDown}
						onTouchStart={onMouseDown}
					>
						<img
							src={volumeSliderImage}
							alt="Volume Slider"
							className="position-absolute cursor-pointer"
							style={{
								top: `${sliderY}%`,
								left: '100%',
								transform: 'translate(-50%, -50%)',
								width: '15px',
								height: '30px',
							}}
							draggable="false"
						/>
					</div>
				)}
			</div>
			{handleMute && (
				<Button
					className={clsx(
						'p-0 btn-no-focus d-25 d-flex flex-column shadow-none',
						{ 'opacity-3': disabled },
						disabled ? 'mt-1' : 'mt-2',
					)}
					color={muteButtonColor}
					onClick={handleMute}
					disabled={disabled}
				>
					{showVolume ? (
						<>
							<div className="content-light fw-bold" style={{ fontSize: '0.85rem', lineHeight: '0.9rem' }}>{Math.ceil(newVolume)}</div>
							<div className="content-light" style={{ fontSize: '0.7rem', lineHeight: '0.7rem' }}>%</div>
						</>
					) : (
						<span className="btn-wrapper--icon">
							{(isMuted || disabled) ? <FaVolumeMute /> : <FaVolumeUp />}
						</span>
					)}
				</Button>
			)}
		</div>
	);
};

PlayerLiveVuMeter.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	handleMute: PropTypes.func,
	isMuted: PropTypes.bool,
	isOnLive: PropTypes.bool,
	isParticipant: PropTypes.bool,
	mediastream: PropTypes.instanceOf(MediaStream),
	onVolumeChange: PropTypes.func,
	showSlider: PropTypes.bool,
	volume: PropTypes.number,
};

PlayerLiveVuMeter.defaultProps = {
	className: '',
	disabled: false,
	handleMute: undefined,
	isMuted: false,
	isOnLive: false,
	isParticipant: false,
	mediastream: undefined,
	onVolumeChange: undefined,
	showSlider: false,
	volume: 100,
};
