// @ts-check

/**
 * @typedef {typeof window & {rollbar?: import('rollbar')}} GlobalWithRollbar
 */

/**
 * @returns {import('rollbar') | undefined}
 */
export const getRollbarInstance = () => {
	const { rollbar } = /** @type {GlobalWithRollbar} */(window);
	return rollbar;
};
