import PropTypes from 'prop-types';
import { lazy, useEffect } from 'react';
import { NavItem, NavLink, TabContent } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader, ModalScreenHeaderNav } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { useUserSettings } from './Context';
import { MembershipModalTab } from './MembershipModalTab';
import './MembershipModal.scss';

const MembershipModalContent = lazy(() => import('./MembershipModalContent'));

export const MembershipModal = ({
	defaultTab,
	isOpen,
	selectedResource,
	resourcePurchaseSuccess,
	selectedChannel,
	selectedGoal,
}) => {
	const { t } = useTranslation();
	const { closeModal, setActiveTab, activeTab } = useUserSettings();

	useEffect(() => {
		setActiveTab(defaultTab);
	}, [defaultTab, setActiveTab]);

	return (
		<ModalScreen
			bodyClassName="py-0"
			contentClassName={activeTab === MembershipModalTab.PRICING ? 'MembershipModalPricing w-100 m-auto' : ''}
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title={t('UserSettings.MembershipModal.membership')}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.MembershipModal.membership')}
								onClick={() => setActiveTab(MembershipModalTab.MEMBERSHIP)}
								active={activeTab === MembershipModalTab.MEMBERSHIP}
							>
								<span>{t('UserSettings.MembershipModal.membership')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('UserSettings.MembershipModal.pricing')}
								onClick={() => setActiveTab(MembershipModalTab.PRICING)}
								active={activeTab === MembershipModalTab.PRICING}
							>
								<span>{t('UserSettings.MembershipModal.pricing')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MembershipModal.billing')}
								onClick={() => setActiveTab(MembershipModalTab.BILLING)}
								active={activeTab === MembershipModalTab.BILLING}
							>
								<span>{t('UserSettings.MembershipModal.billing')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MembershipModal.transactions')}
								onClick={() => setActiveTab(MembershipModalTab.TRANSACTIONS)}
								active={activeTab === MembershipModalTab.TRANSACTIONS}
							>
								<span>{t('UserSettings.MembershipModal.transactions')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MembershipModal.purchase')}
								onClick={() => setActiveTab(MembershipModalTab.PURCHASE)}
								active={activeTab === MembershipModalTab.PURCHASE}
							>
								<span>{t('UserSettings.MembershipModal.purchase')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MembershipModal.cashout')}
								onClick={() => setActiveTab(MembershipModalTab.CASH_OUTS)}
								active={activeTab === MembershipModalTab.CASH_OUTS}
							>
								<span>{t('UserSettings.MembershipModal.cashoutMaj')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="ml-3">
							<NavLink
								title={t('UserSettings.MembershipModal.donationSubscription')}
								onClick={() => setActiveTab(MembershipModalTab.DONATION_SUBSCRIPTION)}
								active={activeTab === MembershipModalTab.DONATION_SUBSCRIPTION}
							>
								<span>{t('UserSettings.MembershipModal.donationSubscription')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<MembershipModalContent
						selectedResource={selectedResource}
						resourcePurchaseSuccess={resourcePurchaseSuccess}
						selectedChannel={selectedChannel}
						selectedGoal={selectedGoal}
					/>
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

MembershipModal.propTypes = {
	defaultTab: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	selectedResource: PropTypes.shape({
		id: PropTypes.string,
		type: PropTypes.string,
	}),
	resourcePurchaseSuccess: PropTypes.func,
	selectedChannel: PropTypes.shape(),
	selectedGoal: PropTypes.shape(),
};

MembershipModal.defaultProps = {
	defaultTab: MembershipModalTab.MEMBERSHIP,
	selectedResource: undefined,
	resourcePurchaseSuccess: undefined,
	selectedChannel: undefined,
	selectedGoal: undefined,
};
