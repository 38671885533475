// @ts-check

import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as groupsApi from '../../api/channel/groups';
import { axiosQueryWrapper, axiosMutationWrapper } from '../utils/axios-wrapper';

export const GROUPS_QUERY_KEYS = {
	fetchGroups: () => ['api', 'channel', 'groups', 'fetch', 'all'],
	fetchGroup: (/** @type {string} */groupId) => ['api', 'channel', 'groups', 'fetch', groupId],
};

export const useFetchGroups = () => useQuery(
	GROUPS_QUERY_KEYS.fetchGroups(),
	axiosQueryWrapper(groupsApi.fetchGroups),
);

export const useFetchGroup = (
	/** @type {string} */groupId,
) => useQuery(
	GROUPS_QUERY_KEYS.fetchGroup(groupId),
	axiosQueryWrapper(groupsApi.fetchGroup, groupId),
	{ enabled: !!groupId },
);

export const useCreateGroup = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(groupsApi.createGroup),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(GROUPS_QUERY_KEYS.fetchGroups());
			},
		},
	);
};

export const useUpdateGroup = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(groupsApi.updateGroup),
		{
			onSuccess: (updatedGroup) => {
				queryClient.invalidateQueries(GROUPS_QUERY_KEYS.fetchGroups());
				queryClient.invalidateQueries(GROUPS_QUERY_KEYS.fetchGroup(updatedGroup._id));
			},
		},
	);
};

export const useDeleteGroup = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(groupsApi.deleteGroup),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(GROUPS_QUERY_KEYS.fetchGroups());
			},
		},
	);
};

export const useLeaveGroup = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(groupsApi.leaveGroup),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(GROUPS_QUERY_KEYS.fetchGroups());
			},
		},
	);
};
