// @ts-check

/**
 * @param {MediaStreamTrack} track
 * @returns {void}
 */
export const stopTrack = (track) => {
	track.stop();
	track.dispatchEvent(new Event('ended'));
	// Firefox do not handle this event triggered manually
};

/** @enum {number} */
export const Resolution = {
	P1080: 1080,
	P720: 720,
	P480: 480,
};

export const USER_MAX_FPS = 24;

// TODO: find why volume is anormaly low
// for external microphones when those constraints are used
export const DEFAULT_AUDIO_CONSTRAINTS = {
	// autoGainControl: false,
	// channelCount: 2,
	// echoCancellation: false,
	// latency: 0,
	// noiseSuppression: false,
	// sampleRate: 48000,
	// sampleSize: 16,
	// volume: 1.0,
};

/** @enum {string} */
export const MediaShareVideoType = {
	SCREEN: 'screen',
	VIDEO: 'video',
	IMAGE: 'image',
};
