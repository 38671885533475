/* eslint-disable react/prop-types */
// @ts-check

import { Alert, Col, Modal, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ButtonLoading } from '../../components/Button/Loading';

/**
 * @typedef {{
 *  children?: React.ReactNode,
 * 	error?: boolean,
 * 	errorMessage?: string,
 * 	isOpen: boolean,
 *  loading?: boolean,
 * 	message?: string,
 * 	onConfirm: () => void,
 * 	onDeny: () => void,
 * }} ConfirmationModalProps
 */

/** @type {React.FC<ConfirmationModalProps>} */
export const ConfirmationModal = ({
	children,
	error = false,
	errorMessage = '',
	isOpen,
	loading = false,
	message,
	onConfirm,
	onDeny,
}) => {
	const { t } = useTranslation();
	return (
		<Modal
			centered
			contentClassName="CurrentShow border-0 shadow-lg rounded overflow-hidden bg-transparent m-auto"
			isOpen={isOpen}
			toggle={onDeny}
			zIndex={2000}
		>
			<Row className="g-0 w-100 bg-dark d-flex justify-content-center align-items-center">
				{message && (
					<Col xs="8" className="mt-4">
						<h4 className="text-center pt-5 pb-3 font-weight-bold">
							{message}
						</h4>
					</Col>
				)}
				{children}
			</Row>
			{error && (
				<Row className="g-0 w-100 bg-dark d-flex justify-content-center align-items-center">
					<Col xs="8">
						<Alert color="danger">{errorMessage || t('Global.error')}</Alert>
					</Col>
				</Row>
			)}
			<Row className="g-0 w-100 p-3 bg-dark d-flex justify-content-end align-items-center">
				<ButtonLoading
					color="neutral-secondary"
					loading={loading}
					onClick={onConfirm}
					size="lg"
				>
					{t('ConfirmationModal.Index.yes')}
				</ButtonLoading>
				<ButtonLoading
					className="ml-1"
					color="neutral-secondary"
					loading={loading}
					onClick={onDeny}
					size="lg"
				>
					{t('ConfirmationModal.Index.no')}
				</ButtonLoading>
			</Row>
		</Modal>
	);
};
