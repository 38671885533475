// @ts-check

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';

import * as socialNetworksApi from '../../api/channel/socialNetworks';
import { axiosQueryWrapper, axiosMutationWrapper } from '../utils/axios-wrapper';
import { VIDEO_QUERY_KEYS } from './videos';

/**
 * @import { IVideoOwnerDto } from '../../api/channel/videos.dto';
 */

export const SOCIAL_NETWORKS_QUERY_KEYS = {
	fetchSocialNetworks: () => ['api', 'channel', 'socialNetworks', 'fetch'],
	fetchVideoWithSocialNetworkUploads: (
		/** @type {string} */videoId,
	) => ['api', 'channel', 'socialNetworks', 'videos', videoId].filter(Boolean),
};

export const useFetchSocialNetworks = () => useQuery(
	SOCIAL_NETWORKS_QUERY_KEYS.fetchSocialNetworks(),
	axiosQueryWrapper(socialNetworksApi.fetchSocialNetworks),
);

export const useLinkSocialNetwork = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(socialNetworksApi.linkSocialNetwork), {
			onSuccess: () => {
				queryClient.invalidateQueries(SOCIAL_NETWORKS_QUERY_KEYS.fetchSocialNetworks());
			},
		},
	);
};

export const useDeleteSocialNetwork = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(socialNetworksApi.deleteSocialNetwork), {
			onSuccess: () => {
				queryClient.invalidateQueries(SOCIAL_NETWORKS_QUERY_KEYS.fetchSocialNetworks());
				queryClient.invalidateQueries(
					SOCIAL_NETWORKS_QUERY_KEYS.fetchVideoWithSocialNetworkUploads(),
				);
				queryClient.invalidateQueries(VIDEO_QUERY_KEYS.fetchVideosBase());
			},
		},
	);
};

export const useUploadVideoToSocialNetwork = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(socialNetworksApi.uploadVideoToSocialNetwork), {
			onSuccess: (updatedVideo) => {
				queryClient.setQueryData(
					SOCIAL_NETWORKS_QUERY_KEYS.fetchVideoWithSocialNetworkUploads(updatedVideo._id),
					updatedVideo,
				);
			},
		},
	);
};

export const useHandleVideoSocialNetworkUploadsStatusChange = () => {
	const queryClient = useQueryClient();

	return useCallback((
		/** @type {IVideoOwnerDto} */updatedVideo,
	) => {
		// Only update if social network uploads are populated
		if (updatedVideo.socialNetworkUploads?.some((u) => typeof u.socialNetwork === 'object')) {
			queryClient.setQueryData(
				SOCIAL_NETWORKS_QUERY_KEYS.fetchVideoWithSocialNetworkUploads(updatedVideo._id),
				updatedVideo,
			);
		}
	}, [queryClient]);
};

/**
 * @param {string} videoId
 */
export const useFetchVideoWithSocialNetworkUploads = (videoId) => useQuery(
	SOCIAL_NETWORKS_QUERY_KEYS.fetchVideoWithSocialNetworkUploads(videoId),
	axiosQueryWrapper(socialNetworksApi.fetchVideoWithSocialNetworkUploads, videoId),
);
