/* eslint-disable react/prop-types */
// @ts-check
import {
	memo,
	createContext,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useInputs } from '../Inputs/Context';
import {
	DEFAULT_AUDIO_CONSTRAINTS,
	Resolution,
	stopTrack,
	USER_MAX_FPS,
} from './utils';
import getUserMedia from '../../lib/getUserMedia';
import { getRollbarInstance } from '../../lib/rollbar';

/*
	My advice: don't touch it unless this is already the end of world.
	And if so, keep in mind that Safari won't fight on your side...
*/

const rollbar = getRollbarInstance();

/**
 * @typedef {{
 *  getDeviceTrack: (
 * 		deviceId: string,
 * 		kind: 'audioinput' | 'videoinput',
 *  ) => UserMediaStreamTrack | undefined,
 *  getInputDeviceFromConfigAndKind: (
 * 		configId: number,
 * 		kind: MediaStreamTrack['kind'],
 * 	) => import('../Inputs').InputDeviceInfoWithConfig | undefined,
 * 	inputDeviceStatuses: { [deviceId: string]: InputDeviceStatus },
 * 	requestInputDevice: (
 * 		deviceCfg: import('../Inputs').InputDeviceInfoWithConfig
 * 	) => Promise<UserMediaStreamTrack[] | undefined>,
 * 	resetInputDeviceStatusById: (deviceId: string) => void,
 * 	stopInputDevice: (
 * 		deviceCfg: import('../Inputs').InputDeviceInfoWithConfig
 * 	) => void,
 * 	userActiveTracks: UserMediaStreamTrack[],
 * 	userAudioActiveTracks: UserMediaStreamTrack[],
 * 	userMediastreams: UserMediaStream[],
 * 	userVideoActiveTracks: UserMediaStreamTrack[],
 * }} IMediaUserContext
 */

export const MediaUserContext = createContext(/** @type {IMediaUserContext} */({}));

export const useMediaUser = () => useContext(MediaUserContext);

/**
 * @typedef {MediaStream & {
 * 	configId?: number,
* }} UserMediaStream
*/

/**
 * @typedef {MediaStreamTrack & {
 * 	configId?: number,
 * 	deviceId?: string,
 * }} UserMediaStreamTrack
 */

/**
 * @typedef {{
 * 	allowAudio?: boolean,
 * 	allowVideo?: boolean,
 * 	children: React.ReactNode,
 *  resolution?: Resolution,
 * }} MediaUserProps
 */

/**
 * @typedef {{
 *  deviceId: string,
 *  kind: MediaStreamTrack['kind'],
 *  configId: number | undefined,
 *  error: Error | undefined,
 *  status: 'disabled' | 'prompt' | 'granted' | 'denied' | 'error',
 * }} InputDeviceStatus
 */

/**
 * @typedef {MediaTrackConstraints
 * 	& { deviceId: { exact: string } }} UserMediaTrackConstraints
 */

/**
 *
 * @param {string} deviceId
 * @param {number} res
 * @returns {UserMediaTrackConstraints}
 */
const getUserMediaVideoConstraints = (deviceId, res) => ({
	deviceId: { exact: deviceId },
	height: { ideal: res },
	frameRate: { ideal: USER_MAX_FPS }, // max: USER_MAX_FPS (24) is not supported by Firefox
	aspectRatio: { ideal: 16 / 9 },
});

/**
 * @param {string} deviceId
 * @param {Omit<MediaTrackConstraints, 'deviceId'>} [constraints]
 * @returns {UserMediaTrackConstraints}
 */
const getUserMediaAudioConstraints = (deviceId, constraints = {}) => ({
	deviceId: { exact: deviceId },
	...DEFAULT_AUDIO_CONSTRAINTS,
	...constraints,
});

/**
 *
 * @param {number} configId
 * @param {'audioinput' | 'videoinput'} deviceKind
 * @returns {{
 * 	configId: number,
 *  deviceCfg: import('../Inputs').InputDeviceInfoWithConfig | undefined,
 *  deviceId: string | undefined,
 *  deviceTrack: UserMediaStreamTrack | undefined,
 *  error: Error | undefined,
 *  inputDeviceStatus: InputDeviceStatus | undefined,
 *  isActive: boolean,
 *  isLoading: boolean,
 *  permission: import('../Inputs').PermissionStatusState | undefined,
 *  toggleInputDevice: () => void,
 * }}
 */
export const useDeviceStatusFromConfig = (configId, deviceKind) => {
	const {
		getDeviceTrack,
		getInputDeviceFromConfigAndKind,
		inputDeviceStatuses,
		resetInputDeviceStatusById,
	} = useMediaUser();
	const {
		activateAudioInput,
		activateVideoInput,
		deactivateAudioInput,
		deactivateVideoInput,
		inputPermissions,
	} = useInputs();

	const deviceCfg = getInputDeviceFromConfigAndKind(configId, deviceKind);
	const deviceId = deviceCfg?.deviceId;
	const deviceTrack = deviceId ? getDeviceTrack(deviceId, deviceKind) : undefined;
	const inputDeviceStatus = deviceId ? inputDeviceStatuses[deviceId] : undefined;
	const isActive = !!deviceTrack;
	const permission = inputPermissions[deviceKind];

	const toggleInputDevice = () => {
		if (isActive) {
			if (deviceKind === 'audioinput') {
				deactivateAudioInput(configId);
			} else {
				deactivateVideoInput(configId);
			}
		} else {
			if (deviceId) {
				resetInputDeviceStatusById(deviceId);
			}
			if (deviceKind === 'audioinput') {
				activateAudioInput(configId);
			} else {
				activateVideoInput(configId);
			}
		}
	};

	return {
		configId,
		deviceCfg,
		deviceId,
		deviceTrack,
		error: inputDeviceStatus?.error,
		inputDeviceStatus,
		isActive: !!deviceTrack,
		isLoading: inputDeviceStatus?.status === 'prompt',
		permission,
		toggleInputDevice,
	};
};

// eslint-disable-next-line prefer-arrow-callback
export const MediaUser = memo(function MediaUser(
	/** @type {MediaUserProps} */
	{
		allowAudio = false,
		allowVideo = false,
		children,
		resolution = Resolution.P720,
	},
) {
	const {
		isDeviceUpdating,
		inputsConfigWithDefaultOverride,
		inputDevicesWithConfig,
		inputPermissions,
	} = useInputs();

	const [userAudioActiveTracks, setUserAudioActiveTracks] = useState(
		/** @type {UserMediaStreamTrack[]} */([]),
	);
	const [userVideoActiveTracks, setUserVideoActiveTracks] = useState(
		/** @type {UserMediaStreamTrack[]} */([]),
	);
	const userActiveTracks = useMemo(
		() => [...userAudioActiveTracks, ...userVideoActiveTracks],
		[userAudioActiveTracks, userVideoActiveTracks],
	);

	const useMediastreamsRef = useRef(
		/** @type {UserMediaStream[]}*/([]),
	); // memoize mediastreams

	const userMediastreams = useMemo(() => {
		if (userActiveTracks.length > 0) {
			const mediaStreams = inputsConfigWithDefaultOverride
				.map((cfg) => {
					const tracks = userActiveTracks.filter((track) => track.configId === cfg.id);
					if (tracks.length <= 0) return undefined;

					const memoizedMediastream = useMediastreamsRef.current.find((m) => m.configId === cfg.id);
					const memoizedMediastreamTracks = memoizedMediastream?.getTracks() || [];
					if (
						tracks.length === memoizedMediastreamTracks?.length
						&& tracks.every((track) => memoizedMediastreamTracks.includes(track))
					) return memoizedMediastream; // return memoized mediastream if tracks are the same

					// Refresh mediastream when tracks change to avoid player image stuck
					/** @type {UserMediaStream} */
					const newMediaStream = new MediaStream(tracks);
					newMediaStream.configId = cfg.id;
					return newMediaStream;
				})
				// remove undefined mediastreams (if no tracks per configId)
				.filter((/** @type {UserMediaStream | undefined} */m) => !!m);

			useMediastreamsRef.current = mediaStreams;
			return mediaStreams;
		}
		return [];
	}, [userActiveTracks, inputsConfigWithDefaultOverride]);

	const userActiveTracksRef = useRef(userActiveTracks);
	userActiveTracksRef.current = userActiveTracks;
	const unmountedRef = useRef(false);

	// cleanup
	useEffect(() => () => {
		unmountedRef.current = true;
		userActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const getDeviceTrack = useCallback(
		/**
		 * @param {string} deviceId
		 * @param {'audioinput' | 'videoinput'} deviceKind
		 * @returns {UserMediaStreamTrack | undefined}
		 */
		(deviceId, deviceKind) => (
			userActiveTracks?.find((track) => {
				const kind = deviceKind === 'audioinput' ? 'audio' : 'video';
				return (
					track.deviceId === deviceId
					&& track.kind === kind
				);
			})
		),
		[userActiveTracks],
	);

	const getInputDeviceFromConfigAndKind = useCallback(
		/**
		 * @param {number} configId
		 * @param {MediaStreamTrack['kind']} kind
		 * @returns {import('../Inputs/Inputs').InputDeviceInfoWithConfig | undefined}
		 * */
		(configId, kind) => {
			const inputDeviceWithConfig = inputDevicesWithConfig.find((deviceCfg) => (
				deviceCfg.inputConfig?.id === configId && deviceCfg.kind === kind
			));
			if (!inputDeviceWithConfig) return undefined;
			return inputDeviceWithConfig;
		},
		[inputDevicesWithConfig],
	);

	const addTrack = useCallback((
		/** @type {UserMediaStreamTrack} */track,
	) => {
		if (track.kind === 'audio') {
			setUserAudioActiveTracks((state) => [
				...(state.filter((t) => t.id !== track.id) || []),
				track,
			]);
		} else if (track.kind === 'video') {
			setUserVideoActiveTracks((state) => [
				...(state.filter((t) => t.id !== track.id) || []),
				track,
			]);
		} else {
			throw new Error(`addTrack: Unknown kind '${track.kind}'`);
		}
	}, []);

	const requestUserMedia = useCallback(
		/**
		 * @param {{
		 *  audio?: UserMediaTrackConstraints,
		 *  video?: UserMediaTrackConstraints,
		 * }} constraints
		 * @param {number} [cfgId]
		 * @returns {Promise<UserMediaStreamTrack[]>}
		 */
		async (constraints, cfgId) => {
			const mediastream = await getUserMedia(constraints);
			if (unmountedRef.current) {
				mediastream.getTracks().forEach((track) => track.stop());
				return [];
			}
			const tracks = /** @type {UserMediaStreamTrack[]} */(mediastream.getTracks());
			tracks.forEach((track) => {
				track.configId = cfgId;
				// eslint-disable-next-line prefer-destructuring
				const kind = /** @type {'audio' | 'video'} */(track.kind);
				track.deviceId = constraints[kind]?.deviceId?.exact?.toString();
				addTrack(track);
			});
			return tracks;
		},
		[addTrack],
	);

	const [inputDeviceStatuses, setInputDeviceStatuses] = useState(
		/** @type {{ [deviceId: string]: InputDeviceStatus }} */({}),
	);

	const setInputDeviceStatus = useCallback(
		/**
		 * @param {import('../Inputs').InputDeviceInfoWithConfig} deviceCfg
		 * @param {InputDeviceStatus['status']} status
		 * @param {InputDeviceStatus['error']} [error]
		 */
		(deviceCfg, status, error) => {
			setInputDeviceStatuses((prevState) => ({
				...prevState,
				[deviceCfg.deviceId]: {
					deviceId: deviceCfg.deviceId,
					configId: deviceCfg.inputConfig?.id,
					kind: deviceCfg.kind,
					status,
					error,
				},
			}));
		},
		[],
	);

	const resetInputDeviceStatusById = useCallback(
		/**
		 * @param {string} deviceId
		 * @returns {void}
		 * */
		(deviceId) => {
			setInputDeviceStatuses((prevState) => {
				const { [deviceId]: _, ...rest } = prevState;
				return rest;
			});
		},
		[],
	);

	const resolutionRef = useRef(resolution);
	resolutionRef.current = resolution;

	const requestInputDevice = useCallback(
		/**
		 * @param {import('../Inputs').InputDeviceInfoWithConfig} deviceCfg
		 * @returns {Promise<UserMediaStreamTrack[] | undefined>}
		 * */
		async (deviceCfg) => {
			if (deviceCfg.kind !== 'audioinput' && deviceCfg.kind !== 'videoinput') {
				throw new Error('Incorrect input devide kind');
			}

			const constraints = {};
			if (deviceCfg.kind === 'audioinput') {
				constraints.audio = getUserMediaAudioConstraints(
					deviceCfg.deviceId,
					{
						echoCancellation: { ideal: true },
						noiseSuppression: { ideal: true },
					},
				);
			}
			if (deviceCfg.kind === 'videoinput') {
				constraints.video = getUserMediaVideoConstraints(
					deviceCfg.deviceId,
					resolutionRef.current, // Use the latest resolution.
					// Dont request a new mediatrack when resolution changes.
					// We will call applyConstraints() on existing track instead.
				);
			}

			setInputDeviceStatus(deviceCfg, 'prompt');
			try {
				const tracks = await requestUserMedia(constraints, deviceCfg.inputConfig?.id);
				if (unmountedRef.current) {
					return undefined;
				}
				setInputDeviceStatus(deviceCfg, 'granted');
				return tracks;
			} catch (/** @type {any} */err) {
				// eslint-disable-next-line no-console
				console.error(err);
				if (rollbar) {
					rollbar.error(err, { constraints });
				}
				if (unmountedRef.current) {
					return undefined;
				}
				const error = err instanceof Error ? err : new Error('Unknown error');
				setInputDeviceStatus(deviceCfg, 'error', error);
			}
			return undefined;
		},
		[requestUserMedia, setInputDeviceStatus],
	);

	const stopInputDevice = useCallback(
		/**
		 * @param {import('../Inputs').InputDeviceInfoWithConfig} deviceCfg
		 * @returns {void}
		 * */
		(deviceCfg) => {
			const tracks = userActiveTracks.filter((track) => (
				track.deviceId === deviceCfg.deviceId
				&& track.kind === (deviceCfg.kind === 'audioinput' ? 'audio' : 'video')
			));
			tracks.forEach((track) => {
				stopTrack(track);
				// Needs this for firefox because the event
				// "ended" is not handled when track is stopped manually
				if (track.kind === 'audio') setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
				if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
			});
			resetInputDeviceStatusById(deviceCfg.deviceId);
		},
		[resetInputDeviceStatusById, userActiveTracks],
	);

	/**
	 * This event is used to reset the input device status
	 * after an update of the input devices.
	 * Some tracks may have been stopped because of the update,
	 * so we need to restart it
	 */
	const handleDeviceFinishUpdating = () => {
		inputDevicesWithConfig.forEach((deviceCfg) => {
			const {
				deviceId,
				kind,
			} = deviceCfg;
			if (kind !== 'audioinput' && kind !== 'videoinput') {
				return;
			}
			const inputDeviceStatus = inputDeviceStatuses[deviceId];
			const activeTrack = getDeviceTrack(deviceId, kind);
			if (!activeTrack && inputDeviceStatus?.status === 'granted') {
				resetInputDeviceStatusById(deviceId);
			}
		});
	};
	const handleDeviceFinishUpdatingRef = useRef(handleDeviceFinishUpdating);
	handleDeviceFinishUpdatingRef.current = handleDeviceFinishUpdating;

	const isDeviceUpdatingPreviousValueRef = useRef(isDeviceUpdating);

	useEffect(() => {
		const previousValue = isDeviceUpdatingPreviousValueRef.current;
		isDeviceUpdatingPreviousValueRef.current = isDeviceUpdating;
		if (!isDeviceUpdating && isDeviceUpdating !== previousValue) {
			handleDeviceFinishUpdatingRef.current();
		}
	}, [isDeviceUpdating, resetInputDeviceStatusById]);

	useEffect(() => {
		if (isDeviceUpdating) {
			// Don't do anything while device list is updating
			return;
		}
		inputDevicesWithConfig.forEach((deviceCfg) => {
			const {
				deviceId,
				enabled,
				inputConfig,
				kind,
			} = deviceCfg;

			if (kind !== 'audioinput' && kind !== 'videoinput') {
				return;
			}

			const inputPermissionGranted = inputPermissions[kind] === 'granted';
			const globalEnabled = kind === 'audioinput' ? allowAudio : allowVideo;

			const inputDeviceStatus = inputDeviceStatuses[deviceId];
			const activeTrack = getDeviceTrack(deviceId, kind);

			if (
				!enabled
				|| !globalEnabled
				|| !inputPermissionGranted
				|| !inputConfig
			) {
				if (activeTrack) {
					stopInputDevice(deviceCfg);
				}
				return;
			}

			if (
				!activeTrack
				&& !inputDeviceStatus?.status
			) {
				requestInputDevice(deviceCfg);
			}
		});
	}, [
		allowAudio,
		allowVideo,
		getDeviceTrack,
		isDeviceUpdating,
		inputDeviceStatuses,
		inputDevicesWithConfig,
		inputPermissions,
		requestInputDevice,
		stopInputDevice,
	]);

	useEffect(() => {
		const handleTrackEnded = (/** @type {Event}*/{ target: track }) => {
			if (!(track instanceof MediaStreamTrack)) return;
			// Commented because it cause a infinite loop if the track is stopped
			// just after being started.
			// For example on safari, the audio track is stopped when we start another
			// audio device. So the previous device track is stopped and the useEffect
			// triggers a new request for the same device. Infinitely.
			// resetInputDeviceStatusById(track.deviceId);
			track.removeEventListener('trackended', handleTrackEnded);
			if (track.kind === 'audio') setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
			if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
		};

		userActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			userActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [
		userActiveTracks,
	]);

	const value = useMemo(() => ({
		getDeviceTrack,
		getInputDeviceFromConfigAndKind,
		inputDeviceStatuses,
		requestInputDevice,
		resetInputDeviceStatusById,
		stopInputDevice,
		userActiveTracks,
		userAudioActiveTracks,
		userMediastreams,
		userVideoActiveTracks,
	}), [
		getDeviceTrack,
		getInputDeviceFromConfigAndKind,
		inputDeviceStatuses,
		requestInputDevice,
		resetInputDeviceStatusById,
		stopInputDevice,
		userActiveTracks,
		userAudioActiveTracks,
		userMediastreams,
		userVideoActiveTracks,
	]);

	return (
		<MediaUserContext.Provider value={value}>
			{children}
		</MediaUserContext.Provider>
	);
});
