// @ts-check

/**
 * @typedef {import('../../lib/store/track').Track} Track
 * @import { Action } from 'redux';
 * */

const ACTION_KEY = 'tracks';

/**
 * actions
 */
export const ADD_TRACK = `${ACTION_KEY}/addTrack`;
export const CLEAR_TRACKS = `${ACTION_KEY}/clearTracks`;
export const REMOVE_TRACK = `${ACTION_KEY}/removeTrack`;

/**
 * @typedef {Action<typeof ADD_TRACK> & { track: Track } |
* 		Action<typeof CLEAR_TRACKS> |
* 		Action<typeof REMOVE_TRACK> & { track: Track }
 * } TrackAction
 * */

/**
 * @param {Track} track
 * @returns {TrackAction}
 */
export const addTrackAction = (track) => ({
	type: ADD_TRACK,
	track,
});

/**
 * @returns {TrackAction}
 */
export const clearTracksAction = () => ({
	type: CLEAR_TRACKS,
});

/**
 * @param {Track} track
 * @returns {TrackAction}
 */
export const removeTrackAction = (track) => ({
	type: REMOVE_TRACK,
	track,
});
