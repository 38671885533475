import PropTypes from 'prop-types';
import {
	Row,
	Col,
	Container,
	Card,
	CardBody,
	CardText,
	CardTitle,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { OrderBundleType } from '../Checkout/Paypal/Order/Button';
import { getResourceLabel } from '../../components/ResourceAccess/helper';
import { currencyLabel } from '../../lib/currency';

const getSuccessMessage = (amount, orderBundleType, currency, resourceType, t) => {
	switch (orderBundleType) {
	case OrderBundleType.PI_POINTS:
		return t('BuyPoints.BuyPointsSuccess.success', { amount, currencyLabel: currency });
	case OrderBundleType.AD_CREDITS:
		return t('BuyPoints.BuyPointsSuccess.successAdCredits', { amount });
	case OrderBundleType.DONATION:
		return t('BuyPoints.BuyPointsSuccess.successDonation', { amount, currencyLabel: currency });
	case OrderBundleType.RESOURCE:
		return t('BuyPoints.BuyPointsSuccess.successResource', { resourceLabel: getResourceLabel(resourceType) });
	default:
		return '';
	}
};

const BuyPointsSuccess = ({ amount, orderBundleType, resourceType }) => {
	const { t } = useTranslation();

	return (
		<Container className="mt-5 mb-5">
			<Row>
				<Col xl={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
					<Card>
						<CardBody className="text-black">
							<>
								<CardTitle>{t('BuyPoints.BuyPointsSuccess.congratulations')}</CardTitle>
								<CardText>
									<p>
										{getSuccessMessage(amount, orderBundleType, currencyLabel, resourceType, t)}
									</p>
								</CardText>
								{[
									OrderBundleType.AD_CREDITS,
									OrderBundleType.PI_POINTS,
								].includes(orderBundleType) && (
									<CardText>{t('BuyPoints.BuyPointsSuccess.willBeAddedToBalance')}</CardText>
								)}
							</>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

BuyPointsSuccess.propTypes = {
	amount: PropTypes.number.isRequired,
	orderBundleType: PropTypes.string,
	resourceType: PropTypes.string,

};

BuyPointsSuccess.defaultProps = {
	orderBundleType: OrderBundleType.PI_POINTS,
	resourceType: '',
};

export default BuyPointsSuccess;
