// @ts-check

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { InputsContext } from './Context';
import { isIOS, isMobileOrIpad } from '../../lib/userAgent';
import getUserMedia from '../../lib/getUserMedia';
import { getRollbarInstance } from '../../lib/rollbar';

const rollbar = getRollbarInstance();

const LOCAL_STORAGE_INPUT_CONFIGS_KEY = 'beeyou_inputDevices';
const DEFAULT_CONFIG_ID = 0;
const DEFAULT_CONFIG_LABEL = `Source ${DEFAULT_CONFIG_ID + 1}`;
const INPUT_CONFIG_VERSION = 10;

/**
 * @typedef {{
 * 	label: string
 * 	id: number
 * 	audioInputOff: boolean
 * 	videoInputOff: boolean
 * 	audioInputId?: string
 * 	audioDeviceLabel?: string
 * 	videoInputId?: string
 * 	videoDeviceLabel?: string
 * }} InputConfig
 */

/**
 * @typedef {Pick<MediaDeviceInfo, 'deviceId'
* 	| 'groupId'
* 	| 'kind'
* 	| 'label'
* > & {
* 	isDefault: boolean
* 	isFront: boolean
* 	isBack: boolean
* }} InputDeviceInfo
*/

/**
 * @typedef {InputDeviceInfo & {
 *  enabled: boolean,
 *  inputConfig: InputConfig | undefined,
 * }} InputDeviceInfoWithConfig
 */

const getSavedInputsConfig = () => {
	const savedConfig = localStorage.getItem(LOCAL_STORAGE_INPUT_CONFIGS_KEY);

	if (savedConfig) {
		/**
		 * @type {{
		 * 	inputsConfig: InputConfig[],
		 * 	__version: number,
		 * }}
		 * */
		const config = JSON.parse(savedConfig);

		// eslint-disable-next-line no-underscore-dangle
		if (config.__version !== INPUT_CONFIG_VERSION) {
			return undefined;
		}

		if (isIOS) {
			// iOS doesn't allow to have multiple cameras enabled at the same time
			return config.inputsConfig.filter((cfg) => cfg.id === DEFAULT_CONFIG_ID);
		}

		return config.inputsConfig;
	}
	return undefined;
};

/**
 * @param {InputConfig[]} inputsConfig
 * */
const saveInputsConfig = (inputsConfig) => localStorage.setItem(
	LOCAL_STORAGE_INPUT_CONFIGS_KEY,
	JSON.stringify({ inputsConfig, __version: INPUT_CONFIG_VERSION }),
);

const DEFAULT_DEVICE_ID = 'default';
const DEFAULT_DEVICE_LABEL = 'Default';

export const DEFAULT_DEVICES = {
	cam: {
		deviceId: 'default',
		label: 'Default camera',
	},
	mic: {
		deviceId: 'default',
		label: 'Default microphone',
	},
};

/** @enum {string} */
export const AVAILABLE_MOBILE_CAMS = {
	FRONT: 'user',
	BACK: 'environment',
};

/** @type {InputConfig} */
const DEFAULT_INPUT_CONFIG = {
	label: DEFAULT_CONFIG_LABEL,
	id: DEFAULT_CONFIG_ID,
	audioInputOff: false,
	videoInputOff: false,
	audioInputId: DEFAULT_DEVICES.mic.deviceId,
	audioDeviceLabel: DEFAULT_DEVICES.mic.label,
	videoInputId: DEFAULT_DEVICES.cam.deviceId,
	videoDeviceLabel: DEFAULT_DEVICES.cam.label,
};

/**
 * @param {InputDeviceInfo[]} devices
 * @param {string} deviceId
 * */
const findDeviceByDeviceId = (devices, deviceId) => {
	if (
		deviceId === DEFAULT_DEVICES.cam.deviceId
		|| deviceId === DEFAULT_DEVICES.mic.deviceId
	) {
		return devices[0];
	}
	return devices.find((device) => device.deviceId === deviceId);
};

/**
 * @typedef {{
 * 	audioDeviceId: string | undefined,
 *  videoDeviceId: string | undefined
 * }} DefaultDevices
 */

/**
 * @param {{ audio: boolean, video: boolean }} constraints
 * @returns {Promise<DefaultDevices>}
 */
const findDefaultDevices = async (constraints) => {
	let mediastream;
	let audioDeviceId;
	let videoDeviceId;
	try {
		mediastream = await getUserMedia(constraints);
		mediastream.getTracks().forEach((track) => {
			if (track.kind === 'audio') {
				audioDeviceId = track.getSettings().deviceId;
			} else if (track.kind === 'video') {
				videoDeviceId = track.getSettings().deviceId;
			}
			track.stop();
		});
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(err);
		throw err;
	} finally {
		if (mediastream) {
			mediastream.getTracks().forEach((track) => track.stop());
		}
	}
	return { audioDeviceId, videoDeviceId };
};

/**
 * @param {AVAILABLE_MOBILE_CAMS} facingMode
 * @returns {Promise<string | undefined>}
 */
const findCameraWithFaceingMode = async (
	/** @type {AVAILABLE_MOBILE_CAMS} */facingMode,
) => {
	const constraints = { video: { facingMode } };
	let mediastream;
	let deviceId;
	try {
		mediastream = await getUserMedia(constraints);
		deviceId = mediastream?.getVideoTracks()[0]?.getSettings().deviceId;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn(err);
	} finally {
		if (mediastream) {
			mediastream.getTracks().forEach((track) => track.stop());
		}
	}
	return deviceId;
};

/**
 * @typedef {{
 *  backDeviceId: string | undefined
 *  frontDeviceId: string | undefined,
 * }} FrontAndBackDevices
 */

/**
 * @returns {Promise<FrontAndBackDevices>}
 */
const findFrontAndBackDeviceIds = async () => {
	const frontDeviceId = await findCameraWithFaceingMode(AVAILABLE_MOBILE_CAMS.FRONT);
	const backDeviceId = await findCameraWithFaceingMode(AVAILABLE_MOBILE_CAMS.BACK);
	return { frontDeviceId, backDeviceId };
};

/**
 * @typedef {{
 * 	children: React.ReactNode
 * }} InputsProps
 */

/**
 * @typedef {'prompt' | 'granted' | 'denied'} PermissionStatusState
 */

/**
 * @typedef {{
 * 	audioinput: PermissionStatusState | undefined
 *  videoinput: PermissionStatusState | undefined
 * }} InputPermissions
 */

export const Inputs = (
	/** @type {InputsProps} */
	{ children },
) => {
	const unmountedRef = useRef(false);
	const [enabled, setEnabled] = useState(false);
	const [inputPermissions, setInputPermissions] = useState(
		/** @type {InputPermissions} */({
			audioinput: undefined,
			videoinput: undefined,
		}),
	);

	const [inputDevices, setInputDevices] = useState(/** @type {InputDeviceInfo[]} */([]));
	const audioInputDevices = useMemo(() => inputDevices.filter((device) => device.kind === 'audioinput'), [inputDevices]);
	const videoInputDevices = useMemo(() => inputDevices.filter((device) => device.kind === 'videoinput'), [inputDevices]);
	const frontDevice = videoInputDevices.find(({ isFront }) => isFront);
	const backDevice = videoInputDevices.find(({ isBack }) => isBack);
	const [defaultFaceingMode, setDefaultFaceingMode] = useState(AVAILABLE_MOBILE_CAMS.FRONT);

	const [inputsConfig, setInputsConfig] = useState(
		() => {
			const savedInputsConfig = getSavedInputsConfig();
			if (savedInputsConfig) {
				return savedInputsConfig;
			}
			return [DEFAULT_INPUT_CONFIG];
		},
	);
	const inputsConfigWithDefaultOverride = useMemo(
		() => inputsConfig.map(/** @type {InputConfig} */(inputConfig) => {
			let inputConfigOverride = inputConfig;
			if (inputConfig.audioInputId === DEFAULT_DEVICES.mic.deviceId) {
				inputConfigOverride = {
					...inputConfigOverride,
					audioInputId: audioInputDevices.find((d) => d.isDefault)?.deviceId || 'none',
				};
			}
			if (inputConfig.videoInputId === DEFAULT_DEVICES.mic.deviceId) {
				inputConfigOverride = {
					...inputConfigOverride,
					videoInputId: videoInputDevices.find((d) => d.isDefault)?.deviceId || 'none',
				};
			}
			return inputConfigOverride;
		}),
		[inputsConfig, audioInputDevices, videoInputDevices],
	);

	const currentlyEnabledDefaultDevices = useMemo(() => {
		const defaultInputConfig = inputsConfig[0];
		/** @type {InputDeviceInfo[]} */
		const enabledDefaultDevices = [];
		if (!defaultInputConfig) {
			return enabledDefaultDevices;
		}
		if (defaultInputConfig.audioInputId && !defaultInputConfig.audioInputOff) {
			const device = findDeviceByDeviceId(audioInputDevices, defaultInputConfig.audioInputId);
			if (device) enabledDefaultDevices.push(device);
		}
		if (defaultInputConfig.videoInputId && !defaultInputConfig.videoInputOff) {
			const device = findDeviceByDeviceId(videoInputDevices, defaultInputConfig.videoInputId);
			if (device) enabledDefaultDevices.push(device);
		}
		return enabledDefaultDevices.filter(Boolean);
	}, [audioInputDevices, inputsConfig, videoInputDevices]);

	const helpers = useMemo(() => {
		const setPartialInputsConfig = (
			/** @type {Partial<InputConfig>} */
			config,
		) => {
			setInputsConfig((s) => (
				s.map((cfg) => {
					if (cfg.id !== config.id) {
						return cfg;
					}
					return {
						...cfg,
						...config,
					};
				})
			));
		};

		return {
			setPartialInputsConfig,
			addNewConfig: (
				/** @type {{ label: string, id: number }} */
				{ label, id },
			) => {
				const firstAvailableAudioInputDevice = [...audioInputDevices]
					.filter((audioInputDevice) => !currentlyEnabledDefaultDevices
						.find((device) => device.deviceId === audioInputDevice.deviceId))
					.find(
						({ deviceId }) => !inputsConfig.find((c) => c.audioInputId === deviceId),
					);
				const firstAvailableVideoInputDevice = [...videoInputDevices]
					.filter((videoInputDevice) => !currentlyEnabledDefaultDevices
						.find((device) => device.deviceId === videoInputDevice.deviceId))
					.find(
						({ deviceId }) => !inputsConfig.find((c) => c.videoInputId === deviceId),
					);
				const config = {
					label,
					id,
					audioInputOff: false,
					videoInputOff: false,
					audioInputId: firstAvailableAudioInputDevice?.deviceId ?? undefined,
					audioDeviceLabel: firstAvailableAudioInputDevice?.label ?? undefined,
					videoInputId: firstAvailableVideoInputDevice?.deviceId ?? undefined,
					videoDeviceLabel: firstAvailableVideoInputDevice?.label ?? undefined,
				};
				setInputsConfig((s) => ([...s, { ...config }]));
			},
			activateAudioInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, audioInputOff: false });
			},
			activateVideoInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, videoInputOff: false });
			},
			deactivateAudioInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, audioInputOff: true });
			},
			deactivateVideoInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, videoInputOff: true });
			},
			deleteConfig: (
				/** @type {number} */configId,
			) => {
				setInputsConfig((s) => s.filter((cfg) => cfg.id !== configId));
			},
			changeAudioInputDevice: (
				/** @type {number} */configId,
				/** @type {string} */id,
				/** @type {string} */audioDeviceLabel,
			) => {
				setPartialInputsConfig({ id: configId, audioInputId: id, audioDeviceLabel });
			},
			changeVideoInputDevice: (
				/** @type {number} */configId,
				/** @type {string | undefined} */id,
				/** @type {string | undefined} */videoDeviceLabel,
			) => {
				setPartialInputsConfig({ id: configId, videoInputId: id, videoDeviceLabel });
			},
			changeConfigName: (
				/** @type {number} */configId,
				/** @type {string} */name,
			) => {
				setPartialInputsConfig({ id: configId, label: name });
			},
		};
	}, [
		audioInputDevices,
		currentlyEnabledDefaultDevices,
		inputsConfig,
		videoInputDevices,
	]);

	const swapFrontAndBackCamera = useCallback(async () => {
		if (!isMobileOrIpad) {
			// eslint-disable-next-line no-console
			console.error('swapFrontAndBackCamera() is only available on mobile');
			return;
		}
		if (defaultFaceingMode === AVAILABLE_MOBILE_CAMS.FRONT && backDevice) {
			helpers.changeVideoInputDevice(0, backDevice.deviceId, backDevice.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.BACK);
		} else if (frontDevice) {
			helpers.changeVideoInputDevice(0, frontDevice.deviceId, frontDevice.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.FRONT);
		}
	}, [helpers, backDevice, frontDevice, defaultFaceingMode]);

	const setTargetCamera = useCallback((
		/** @type {AVAILABLE_MOBILE_CAMS} */TARGET,
	) => {
		if (TARGET !== AVAILABLE_MOBILE_CAMS.BACK
			&& TARGET !== AVAILABLE_MOBILE_CAMS.FRONT) {
			// eslint-disable-next-line no-console
			console.error('setTargetCamera() only accepts "back" or "front" as parameter');
			return;
		}
		if (TARGET === AVAILABLE_MOBILE_CAMS.BACK && backDevice) {
			helpers.changeVideoInputDevice(0, backDevice.deviceId, backDevice.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.BACK);
		} else if (frontDevice) {
			helpers.changeVideoInputDevice(0, frontDevice.deviceId, frontDevice.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.FRONT);
		}
	}, [backDevice, frontDevice, helpers]);

	const [isDeviceUpdating, setIsDeviceUpdating] = useState(0);

	const inputPermissionsRef = useRef(inputPermissions);
	inputPermissionsRef.current = inputPermissions;

	const updateInputDevices = useCallback(async () => {
		/** @type {MediaDeviceInfo[]} */
		let devicesInfo = [];

		/** @type {DefaultDevices} */
		let defaultDevices;
		/** @type {FrontAndBackDevices} */
		let frontAndBackDevices;

		try {
			const grantedAudio = inputPermissionsRef.current.audioinput === 'granted';
			const grantedVideo = inputPermissionsRef.current.videoinput === 'granted';

			if (!grantedAudio && !grantedVideo) {
				throw new Error('No permissions');
			}

			defaultDevices = await findDefaultDevices({
				audio: grantedAudio,
				video: grantedVideo,
			});

			if (unmountedRef.current) return;

			if (grantedVideo && isMobileOrIpad) {
				frontAndBackDevices = await findFrontAndBackDeviceIds();
				if (unmountedRef.current) return;
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
			if (unmountedRef.current) return;
		}

		try {
			devicesInfo = await navigator.mediaDevices.enumerateDevices();
			if (unmountedRef.current) return;
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
			if (unmountedRef.current) return;
		}

		/**
		 * @param {MediaDeviceInfo} device
		 */
		const isDefaultDevice = (device) => !!devicesInfo.find((d) => (
			d.groupId === device.groupId
			&& (
				d.deviceId === DEFAULT_DEVICE_ID
				|| d.deviceId === ''
			)
		));

		/**
		 * @param {MediaDeviceInfo} device
		 */
		const isVirtualDevice = (device) => {
			const upperLabel = (device.label || '').toUpperCase();
			if (upperLabel.includes('OBS ')) return true;
			if (upperLabel.includes('VIRTUAL')) return true;
			return false;
		};

		const uniqueDevicesInfo = devicesInfo
			// Firefox fix duplicates video input devices
			// .filter(
			// 	(device, index, self) => index === self.findIndex((d) => (
			// 		d.deviceId === device.deviceId
			// 		&& d.kind === device.kind
			// 	)),
			// )
			/* removing default device because it's duplicated
			so it could be chosen in thow different input configs */
			// .filter((deviceInfo) => (
			// 	deviceInfo.deviceId !== DEFAULT_DEVICE_ID
			// 	&& deviceInfo.deviceId !== ''
			// ))
			.sort((a, b) => {
				// Keep order of different devices
				if (a.kind !== b.kind) { return 0; }

				// Devices that have a duplicate which has a "default" deviceId are first
				let aScore = isDefaultDevice(a) ? 1 : 0;
				let bScore = isDefaultDevice(b) ? 1 : 0;

				const diff = bScore - aScore;
				if (diff !== 0) return diff;

				// Virtual devices are last
				aScore = isVirtualDevice(a) ? 0 : 1;
				bScore = isVirtualDevice(b) ? 0 : 1;

				return bScore - aScore;
			})
			.map((deviceInfo) => {
				const isDefault = (
					deviceInfo.deviceId === defaultDevices?.audioDeviceId
					|| deviceInfo.deviceId === defaultDevices?.videoDeviceId
				);
				const isFront = deviceInfo.deviceId === frontAndBackDevices?.frontDeviceId;
				const isBack = deviceInfo.deviceId === frontAndBackDevices?.backDeviceId;
				return {
					deviceId: deviceInfo.deviceId,
					groupId: deviceInfo.groupId,
					isBack,
					isDefault,
					isFront,
					kind: deviceInfo.kind,
					label: deviceInfo.label || deviceInfo.deviceId || DEFAULT_DEVICE_LABEL,
				};
			});

		setInputDevices(uniqueDevicesInfo);
	}, []);

	const nextFunctionRef = useRef(
		/** @type {(() => Promise<void>) | undefined} */(undefined),
	);
	const isRunningRef = useRef(false);
	const updateInputDevicesRef = useRef(updateInputDevices);
	updateInputDevicesRef.current = updateInputDevices;

	/**
	 * Schedule update of input devices, so it doesnt run in parallel.
	 * And only the last scheduled call will be executed.
	 */
	const scheduleUpdateInputDevices = useCallback(() => {
		nextFunctionRef.current = async () => {
			nextFunctionRef.current = undefined;
			if (unmountedRef.current) {
				return;
			}
			isRunningRef.current = true;
			try {
				setIsDeviceUpdating((s) => s + 1);
				await updateInputDevicesRef.current();
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
			isRunningRef.current = false;
			if (unmountedRef.current) {
				return;
			}
			const nextFunction = /** @type {(() => Promise<void>) | undefined} */(
				nextFunctionRef.current
			);
			if (nextFunction) {
				nextFunction();
			}
			// Reduce counter after calling nextFunction, so it doesnt go to 0
			// before the nextFunction is started.
			setIsDeviceUpdating((s) => s - 1);
		};

		if (!isRunningRef.current) {
			nextFunctionRef.current();
		}
	}, []);

	const requestInputPermissions = useCallback(async (
		/** @type {{ audioinput: boolean, videoinput: boolean }} */
		permissions,
	) => {
		const constraints = { audio: false, video: false };

		if (permissions.audioinput) {
			constraints.audio = true;
		}
		if (permissions.videoinput) {
			constraints.video = true;
		}
		if (!constraints.audio && !constraints.video) return;

		let mediastream;
		try {
			setInputPermissions((s) => ({
				...s,
				audioinput: constraints.audio ? 'prompt' : s.audioinput,
				videoinput: constraints.video ? 'prompt' : s.videoinput,
			}));
			mediastream = await getUserMedia(constraints);
			mediastream.getTracks().forEach((track) => track.stop());
			if (unmountedRef.current) return;
			setInputPermissions((s) => ({
				...s,
				audioinput: constraints.audio ? 'granted' : s.audioinput,
				videoinput: constraints.video ? 'granted' : s.videoinput,
			}));
		} catch (/** @type {any} */err) {
			// eslint-disable-next-line no-console
			console.error(err);
			if (rollbar) {
				rollbar.error(err, { constraints });
			}
			if (unmountedRef.current) return;
			setInputPermissions((s) => ({
				...s,
				audioinput: constraints.audio ? 'denied' : s.audioinput,
				videoinput: constraints.video ? 'denied' : s.videoinput,
			}));
			throw err;
		} finally {
			if (mediastream) {
				mediastream.getTracks().forEach((track) => track.stop());
			}
		}
	}, []);

	useEffect(() => {
		const handleDeviceChange = () => {
			scheduleUpdateInputDevices();
		};

		/**
		 * @type {{
		 *  audioinput?: PermissionStatusState,
		 *  videoinput?: PermissionStatusState,
		 * }}
		 */
		const nextValue = {};

		/**
		 * Debounce call of setInputPermissions to avoid multiple calls when
		 * both camera and microphone permissions are changed at the same time.
		 */
		const updateInputPermissions = debounce(() => {
			const nextValueCopy = { ...nextValue };
			setInputPermissions((s) => ({
				...s,
				...nextValueCopy,
			}));
			delete nextValue.audioinput;
			delete nextValue.videoinput;
		}, 100);

		/**
		 * Handle change of camera permission status
		 * @this {PermissionStatus}
		 */
		function handleCameraPermissionStatusChange() {
			const { state } = this;
			nextValue.videoinput = state;
			updateInputPermissions();
		}

		/**
		 * Handle change of microphone permission status
		 * @this {PermissionStatus}
		 */
		function handleMicrophonePermissionStatusChange() {
			const { state } = this;
			nextValue.audioinput = state;
			updateInputPermissions();
		}

		/** @type {PermissionStatus | undefined} */
		let cameraPermissionStatus;
		/** @type {PermissionStatus | undefined} */
		let microphonePermissionStatus;

		/* "enabled" flag to avoid asking permission on safari until the Media component is enabled.
		See Media component. */
		if (enabled) {
			if (navigator.mediaDevices && navigator.mediaDevices.addEventListener) {
				navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
			}

			if (navigator.permissions) {
				Promise.allSettled([
					navigator.permissions
						.query({ name: /** @type {PermissionName} */('camera') })
						.then((permissionStatus) => {
							cameraPermissionStatus = permissionStatus;
							permissionStatus.onchange = handleCameraPermissionStatusChange;
						}),
					navigator.permissions
						.query({ name: /** @type {PermissionName} */('microphone') })
						.then((permissionStatus) => {
							microphonePermissionStatus = permissionStatus;
							permissionStatus.onchange = handleMicrophonePermissionStatusChange;
						}),
				]).then(() => {
					setInputPermissions((s) => ({
						...s,
						audioinput: microphonePermissionStatus?.state || s.audioinput,
						videoinput: cameraPermissionStatus?.state || s.videoinput,
					}));
					requestInputPermissions({
						audioinput: microphonePermissionStatus?.state !== 'granted',
						videoinput: cameraPermissionStatus?.state !== 'granted',
					}).catch(() => {});
				});
			} else {
				requestInputPermissions({
					audioinput: true,
					videoinput: true,
				}).catch(() => {});
			}
		}

		return () => {
			if (navigator.mediaDevices && navigator.mediaDevices.removeEventListener) {
				navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
			}
			if (cameraPermissionStatus) {
				cameraPermissionStatus.onchange = null;
			}
			if (microphonePermissionStatus) {
				microphonePermissionStatus.onchange = null;
			}
		};
	}, [
		enabled,
		requestInputPermissions,
		scheduleUpdateInputDevices,
	]);

	useEffect(() => {
		if (
			inputPermissions.audioinput
			|| inputPermissions.videoinput
		) {
			scheduleUpdateInputDevices();
		}
	}, [inputPermissions, scheduleUpdateInputDevices]);

	const inputDevicesWithConfig = useMemo(
		() => {
			const devices = inputDevices.map((device) => {
				const { kind } = device;

				if (kind === 'audioinput') {
					const inputConfig = inputsConfigWithDefaultOverride.find(
						(cfg) => cfg.audioInputId === device.deviceId,
					);
					return {
						...device,
						enabled: !!inputConfig && !inputConfig.audioInputOff,
						inputConfig,
					};
				}

				const inputConfig = inputsConfigWithDefaultOverride.find(
					(cfg) => cfg.videoInputId === device.deviceId,
				);
				return {
					...device,
					enabled: !!inputConfig && !inputConfig.videoInputOff,
					inputConfig,
				};
			});
			return devices;
		},
		[inputDevices, inputsConfigWithDefaultOverride],
	);

	// Store input config in localstorage
	useEffect(() => { saveInputsConfig(inputsConfig); }, [inputsConfig]);

	useEffect(() => {
		const defaultInputConfig = inputsConfig?.find(({ id }) => id === DEFAULT_CONFIG_ID);

		if (!defaultInputConfig) {
			setInputsConfig((s) => [DEFAULT_INPUT_CONFIG, ...s]);
		}
	}, [videoInputDevices, audioInputDevices, inputsConfig, helpers]);

	useEffect(() => () => {
		unmountedRef.current = false;
	}, []);

	const value = useMemo(() => ({
		...helpers,
		audioInputDevices,
		currentlyEnabledDefaultDevices,
		defaultFaceingMode,
		isDeviceUpdating: isDeviceUpdating > 0,
		inputsConfig,
		inputsConfigWithDefaultOverride,
		inputDevices,
		inputDevicesWithConfig,
		inputPermissions,
		requestInputPermissions,
		setDefaultFaceingMode,
		setEnabled,
		setInputDevices,
		setInputsConfig,
		setTargetCamera,
		swapFrontAndBackCamera,
		videoInputDevices,
	}), [
		audioInputDevices,
		currentlyEnabledDefaultDevices,
		defaultFaceingMode,
		helpers,
		isDeviceUpdating,
		inputsConfig,
		inputsConfigWithDefaultOverride,
		inputDevices,
		inputDevicesWithConfig,
		inputPermissions,
		requestInputPermissions,
		setDefaultFaceingMode,
		setTargetCamera,
		swapFrontAndBackCamera,
		videoInputDevices,
	]);

	return (
		<InputsContext.Provider value={value}>
			{children}
		</InputsContext.Provider>
	);
};

Inputs.propTypes = {
	children: PropTypes.node.isRequired,
};
