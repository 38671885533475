// @ts-check
import {
	createContext,
	Children,
	useContext,
	useState,
	useMemo,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
	exitFullscreen as exitFullscreenLib,
	requestFullscreen as requestFullscreenLib,
} from '../../../lib/requestFullscreen';

/** @enum {string} */
export const ModeScreenLayout = {
	FULLSCREEN: 'FULLSCREEN',
	NORMAL: 'NORMAL',
};

/**
 * @typedef {{
 * 	currentModeScreen: ModeScreenLayout,
 * 	exitFullscreen: () => void,
 * 	requestFullscreen: () => void,
 * 	setCurrentModeScreen: (mode: ModeScreenLayout) => void,
 * 	toggleFullscreen: () => void,
 * }} IScreenModeContext
 */

const ScreenModeContext = createContext(/** @type {IScreenModeContext} */({}));

export const useScreenMode = () => {
	const screenModeContext = useContext(ScreenModeContext);
	// type guard (removes undefined type)
	if (!screenModeContext) {
		throw new Error('useScreenMode must be used within a ScreenModeProvider');
	}
	return screenModeContext;
};

/**
 * @typedef {{
* 	children: React.ReactNode,
* }} ScreenModeProviderProps
*/

export const ScreenModeProvider = (
	/** @type {ScreenModeProviderProps} */
	{ children },
) => {
	const [currentModeScreen, setCurrentModeScreen] = useState(ModeScreenLayout.NORMAL);

	const requestFullscreenEventRef = useRef(() => {
		const firstChild = /** @type {React.ReactElement} */((Children.toArray(children))[0]);
		const id = /** @type {string?} */(firstChild?.props?.id);
		requestFullscreenLib(id
			? document.documentElement.querySelector(`#${id}`)
			: document.documentElement);
		setCurrentModeScreen(ModeScreenLayout.FULLSCREEN);
	});

	const requestFullscreen = useCallback(() => {
		requestFullscreenEventRef.current();
	}, []);

	const exitFullscreenEventRef = useRef(() => {
		exitFullscreenLib();
		setCurrentModeScreen(ModeScreenLayout.NORMAL);
	});

	const exitFullscreen = useCallback(() => {
		exitFullscreenEventRef.current();
	}, []);

	const toggleFullscreen = useCallback(() => {
		if (currentModeScreen === ModeScreenLayout.NORMAL) requestFullscreenEventRef.current();
		else exitFullscreenEventRef.current();
	}, [currentModeScreen]);

	useEffect(() => {
		const handleFullscreenChange = () => {
			if (!document.fullscreenElement) setCurrentModeScreen(ModeScreenLayout.NORMAL);
		};

		// eslint-disable-next-line no-console
		const handleFullscreenError = () => console.error('handleFullscreenError');

		document.addEventListener('fullscreenchange', handleFullscreenChange, false);
		document.addEventListener('webkitfullscreenchange', handleFullscreenChange, false);
		document.addEventListener('fullscreenerror', handleFullscreenError, false);
		document.addEventListener('webkitfullscreenerror', handleFullscreenError, false);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange, false);
			document.removeEventListener('webkitfullscreenchange', handleFullscreenChange, false);
			document.removeEventListener('fullscreenerror', handleFullscreenError, false);
			document.removeEventListener('webkitfullscreenerror', handleFullscreenError, false);
		};
	});

	const contextValue = useMemo(() => ({
		currentModeScreen,
		exitFullscreen,
		requestFullscreen,
		setCurrentModeScreen,
		toggleFullscreen,
	}), [
		currentModeScreen,
		exitFullscreen,
		requestFullscreen,
		toggleFullscreen,
	]);

	return (
		<ScreenModeContext.Provider value={contextValue}>
			{children}
		</ScreenModeContext.Provider>
	);
};

ScreenModeProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
